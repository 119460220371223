import { useEffect, useState } from "react";
import CGUDialog from "./CGUDialog";
import { useGetIdentity } from "react-admin";

export const CGUOnLoad = () => {
  const { data, isPending, error } = useGetIdentity();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (data) {
      setIsLoaded(true);
    }
  }, [data]);

  return (
    <>
      {isLoaded && !data.cgu_accepted_at &&
        <CGUDialog
          userId={data.id as number}
          cguAlreadyAccepted={!!data.cgu_accepted_at} // use cgu_accepted_at value as boolean
          forceAccept
        />
      }
    </>
  )
};

export default CGUOnLoad;