/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-useless-escape */
import fr from "ra-language-french";

export default {
  ...fr,
  login: {
    email: "Email",
    password: "Mot de passe",
    sign_in: "Se connecter",
    forgot_password: "Mot de passe oublié ?",
    remember_me: "Se souvenir de moi",
    email_required: "L'email est requis",
    password_required: "Le mot de passe est requis",
    logout: "Se déconnecter",
  },
  welcome_to: "Bienvenue dans l'",
  plateform_name: "UniVR Manager",
  resources: {
    dashboard: "Tableau de bord",
    experiences: "Expériences",
    users: "Utilisateurs",
    licenses: "Licences d'expériences",
    tags: "Tags",
    organizations: "Organisations",
    "experience-groups": "Groupes d'expériences",
    create: "Créer",
    profile: "Profil",
    my_account: "Mon compte",
    my_organization: "Mon organisation",
    my_users: "Mes utilisateurs",
    my_experiences: "Mes expériences",
    "service-licenses": "Licences de services",
    devices: "Appareils",
  },
  dashboard: {
    sessions_duration: "Durée totale des sessions",
    utilizations: "Utilisations",
    analytics: "Explorez les analyses de vos données",
    external_link: "Découvrir",
    most_used_experiences: "Applications les plus utilisées",
    monthly_utilizations: "Utilisations mensuelles",
    number_of_utilizations: "Nombre d'utilisations",
    empty_top_list: "Aucune expérience n'a encore été ajoutée.",
    news: "Nouveautés",
  },
  misc: {
    last_updates: "Dernières mises à jour",
    total: "Total",
    see_more: "Voir plus",
    no_data: "Aucune donnée",
    name: "Nom",
    edit: "Modifier",
    search: "Rechercher",
    options: "Options",
    upload_image_helper:
      "Formats acceptés : .jpg, .jpeg, .png | Taille maximale : 5 Mo | Dimensions maximales : 1920x1080px",
    general_informations: "Informations générales",
    start_date: "Date de début",
    end_date: "Date de fin",
    specific_users: "Utilisateurs spécifiques",
    id: "ID",
    last_update: "Dernière mise à jour",
    publish: "Publier",
    next: "Suivant",
    back: "Retour",
    seventy_characters_max: "70 caractères maximum",
    description: "Description",
    image: "Image",
    zip_file: "Fichier zip",
    new_zip_file: "Nouveau fichier zip",
    archive_exe_video: "Archive contenant l'exécutable ou la vidéo à 360°.",
    video_formats_supported:
      "Formats vidéo pris en charge : .mp4, .mov, .webm, .avi, .mkv, .wmv, .flv, .mpeg-2, .gifv.",
    url: "URL",
    share_options: "Options de partage",
    type: "Type",
    build_helper:
      "Consultez la <a\n" +
      '                href="https://univr-solutions.notion.site/UniVR-MANAGER-d82a993e4dcd4b3e8749e2522bd8ecc1"\n' +
      '                target="_blank" rel="noopener noreferrer">documentation</a> pour plus\n' +
      "                d'informations.",
    email_domain: "Domaine email",
    battery: "Batterie",
    last_seen: "Dernière connexion",
    action: "Action",
    parameters: "Paramètres",
    status: "Statut",
    close: "Fermer",
    cancel: "Annuler",
    install: "Installer",
    delete: "Supprimer",
    yes: "Oui",
    no: "Non",
    coming_soon: "Bientôt disponible",
    welcome: "Bienvenue !",
    logo: "Logo",
    undefined: "Non défini",
    learn_more: "En savoir plus",
    read: "Lire",
    accept: "J'accepte",
  },
  profile: {
    title: "Profil",
    first_name: "Prénom",
    last_name: "Nom",
    email: "Email",
    organization: "Organisation",
    roles: "Rôles",
    password: "Mot de passe",
    save: "Sauvegarder",
    sites: "Sites",
    update_success: "Profil mis à jour avec succès",
    cgu_shortcut: "CGU",
    cgu_accepted_on: "Acceptées le",
    view_cgu: "Voir les CGU",
    cgu_title: "Conditions générales d'utilisation",
    cgu_content: "Contenu des CGU",
    cgu_accept_label:
      "Je reconnais avoir lu et accepté les conditions d'utilisation de UniVR.",
    cgu_success: "Conditions générales d'utilisation acceptées avec succès",
    duplicate_error: "Les conditions générales d'utilisation ont déjà été acceptées.",
  },
  organizations: {
    create: "Créer une organisation",
    name: "Nom de l'organisation",
    singular_name: "Organisation",
    not_found: "Aucune organisation trouvée",
    experiences: {
      images: {
        default: "Image par défaut de l'expérience",
      },
    },
    list: "Liste des organisations",
    list_helper: "avec lesquelles vous souhaitez partager cette expérience.",
    "service-license-status": "Statut licence",
    univrsity: "UniVRsity",
  },
  tags: {
    categories: {
      index: "Catégories",
      create: "Créer une catégorie",
    },
    editors: {
      index: "Éditeurs",
      create: "Créer un éditeur",
      not_found: "Aucun éditeur trouvé",
    },
    headsets: {
      index: "Casques",
      create: "Créer un casque",
      not_found: "Aucun casque trouvé",
    },
    tags: {
      index: "Tags",
      create: "Créer un tag",
      not_found: "Aucun tag trouvé",
    },
  },
  licenses: {
    create: "Créer une licence d'expérience",
  },
  notifications: {
    title: "Expiration des licences",
    none: "Pas de notification",
  },
  "experience-groups": {
    name: "Groupes d'expériences",
    singular_name: "Groupe d'expérience",
    create: "Créer un groupe d'expérience",
  },
  experiences: {
    singular_name: "Expérience",
    create: "Ajouter une expérience",
    delete_confirm: "Êtes-vous sûr de vouloir supprimer cette expérience ?",
    new_version: "Nouvelle version",
    first_version: "Première version",
    steps: {
      general: "Général",
      technical: "Technique",
      share: "Partage",
      advanced_interface: "Interface avancée",
      simplified_interface: "Interface simplifiée",
    },
    experience_title: "Titre de l'expérience",
    types: {
      index: "Type d'expérience",
      build: "Expérience développée (Fichier .exe, .apk)",
      video: "Vidéo à 360°",
      browser: "URL vers l'expérience web",
      oculus: "Chemin vers l'application Oculus PC",
      package: "Application Meta Quest Store",
      installer: "Expérience avec installateur",
    },
    installed_software_name: "Nom du logiciel installé",
    package_name: "Nom du package",
    meta_quest_store_package_name: "Nom du package Meta Quest Store",
    exectuable_name: "Nom de l'exécutable",
    installer_name: "Nom de l'installateur",
    installer_help_text:
      "Nom de l'exécutable à lancer, doit se terminer par .exe ou .apk.",
    version: {
      initial: "Version initiale",
      description: "Note de mise à jour",
      new_version: "Nouvelle version",
    },
    shared_univrsity: "Partage UniVRsity",
  },
  dates: {
    duration: "Durée",
    "1_month": "1 mois",
    "6_months": "6 mois",
    "3_months": "3 mois",
    "1_year": "1 an",
    "3_years": "3 ans",
    unlimited: "Illimité",
    custom: "Personnalisé",
    same_as_service_license: "Identique à la licence de services",
    creation_date: "Date de création",
  },
  users: {
    create: "Créer un utilisateur",
    roles: "Rôles",
    change_password_question: "Changer le mot de passe ?",
    user: "Utilisateur",
  },
  "service-licenses": {
    types: {
      paid: "Classique",
      trial: "Essai",
      unlimited: "Illimité",
      trial_helper: "Les licences d'essai sont limitées à 30 jours.",
    },
    services: {
      index: "Services",
      home: "Home",
      manager: "Manager",
      analytics: "Analytics",
    },
    create: "Créer une licence de services",
    status: "Statut",
  },
  status: {
    index: "Statut",
    active: "Actif",
    expiring: "Expiration imminente",
    expired: "Expiré",
    undefined: "Non défini",
  },
  devices: {
    create: "Créer un appareil",
    actions: {
      title: "Actions",
      action_dialog: {
        title: "Actions en attente sur l'appareil",
        banner: {
          done: "%{count} terminés",
          errors: "%{count} erreurs",
          total: "%{count} au total",
          delete: "Effacer les actions terminées",
        },
      },
      install_apps_dialog: {
        menu_item: "Installer des applications",
        title: "Installation d'applications sur les appareils",
      },
      install: "Installer",
      shutdown: "Éteindre",
      reboot: "Redémarrer",
      delete_dialog: {
        title: "Confirmer la suppression des appareils",
        confirmation: "Êtes-vous sûr de vouloir supprimer ces appareils ?",
        information: "Vous pourrez toujours les réappairer via l'UniVR Home.",
      },
      status: {
        pending: "En attente",
        done: "Terminé",
        error: "Erreur",
        progress: "En cours",
        undefined: "Non défini",
      },
    },
  },
  roles: {
    admin: "Administrateur",
    adminOrga: "Administrateur d'organisation",
    normal: "Utilisateur",
    contentCreator: "Créateur de contenu",
  },
  navigation: {
    show: "Détails",
  },
};
