import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  usePermissions,
  useRedirect,
  useTranslate,
  useUnselectAll,
} from "react-admin";
import React, { useEffect, useState } from "react";
import { hasAccess } from "../../global/permissions";
import ListLayout from "../Layout/ListLayout";
import DefaultFilter from "../Layout/Filters/DefaultFilter";
import SkeletonList from "../Loading/SkeletonList";
import { Stack } from "@mui/material";
import DeleteWithConfirmDialog from "./Dialogs/DeleteWithConfirmDialog";
import MenuActions from "./Menus/MenuActions";
import ActionsDialog from "./Dialogs/ActionsDialog";
import BatteryField from "./Fields/BatteryField";

const BulkActionButtons = () => {
  return (
    <Stack direction={"row"} alignItems={"center"} gap={1} flexWrap={"wrap"}>
      <DeleteWithConfirmDialog />
      <MenuActions />
    </Stack>
  );
};

const DeviceList = () => {
  const organization = JSON.parse(localStorage.getItem("organization"));
  const redirect = useRedirect();
  const { permissions } = usePermissions();
  const [loading, setLoading] = useState(true);
  const translate = useTranslate();
  const unselectAll = useUnselectAll("devices");

  useEffect(() => {
    return () => unselectAll();
  }, []);

  useEffect(() => {
    if (!organization || !permissions) setLoading(true);
    else setLoading(false);
  }, [organization, permissions]);

  if (!hasAccess({ name: "devices", crudType: "list" }, permissions))
    redirect("/");

  const filterValues = !permissions.includes("admin")
    ? { organization_id: organization.id }
    : null;

  return (
    <ListLayout title={translate("resources.devices")}>
      {!loading ? (
        <List
          exporter={false}
          perPage={50}
          filters={<DefaultFilter />}
          sort={{ field: "updated_at", order: "DESC" }}
          filter={filterValues}
          actions={null}
          resource={"devices"}
        >
          <Datagrid bulkActionButtons={<BulkActionButtons />} rowClick={false}>
            <TextField source='id' label={translate("misc.id")} />
            <TextField source='name' label={translate("misc.name")} />
            <BatteryField source='battery' label={translate("misc.battery")} />
            <ReferenceField
              label={translate("organizations.singular_name")}
              source='organization_id'
              reference='organizations'
            >
              <TextField source='name' />
            </ReferenceField>
            <ReferenceField
              label={translate("users.user")}
              source='user_id'
              reference='users'
            >
              <TextField source='full_name' />
            </ReferenceField>
            <DateField
              source='timestamp'
              label={translate("misc.last_seen")}
              showTime
            />
            <ActionsDialog />
          </Datagrid>
        </List>
      ) : (
        <SkeletonList />
      )}
    </ListLayout>
  );
};

export default DeviceList;
