import React from "react";
import CustomInput from "./CustomInput";
import {AutocompleteArrayInput, ReferenceArrayInput, required} from "react-admin";
import {NameField} from "../../Experiences/Form/ExperienceForm";
import AutoCreateTag from "../../Tags/AutoCreateTag";

interface TagInputProps {
    source: string;
    type: string;
    noOptionsText?: string;
    createText?: string;
    label?: string;
    required?: boolean;
}

const TagInput: React.FC<TagInputProps> = ({label, createText, noOptionsText, source, type, required: req}) => {
    return (
        <CustomInput
            inputComponent={ReferenceArrayInput}
            inputComponentProps={{
                source,
                reference: 'tags',
                perPage: 1000,
                sort: {field: 'name', order: 'ASC'},
                filter: {type},
                optionText: <NameField/>,
                optionValue: 'name',
                validate: required(),
                children: (
                    <AutocompleteArrayInput
                        label={null}
                        sx={{
                            width: 'fit-content',
                            '& .MuiFormHelperText-root': {display: 'none'},
                            '& .MuiFormControl-root': {margin: 0},
                        }}
                        multiple
                        inputText={(option) => option?.name}
                        matchSuggestion={(filterValue, suggestion) =>
                            suggestion?.name.toLowerCase().includes(filterValue?.toLowerCase())
                        }
                        noOptionsText={noOptionsText}
                        disableCloseOnSelect
                        blurOnSelect={false}
                        create={<AutoCreateTag type={type}/>}
                        allowCreate
                        createText={createText}
                        size={'small'}
                    />
                )
            }}
            label={label}
            required={req}
        />
    )
}

TagInput.defaultProps = {
    noOptionsText: 'Aucun tag trouvé',
    createText: 'Créer un tag',
    label: 'Tags',
    required: true
}

export default TagInput;