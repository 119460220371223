import {useListContext, useRefresh, useTranslate} from "react-admin";
import React from "react";
import {createDeviceActions} from "../../../services/api/devices.service";
import {Box, Button} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InstallAppsDialog from "../Dialogs/InstallAppsDialog";

const MenuActions = () => {
    const {selectedIds} = useListContext();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const refresh = useRefresh();
    const translate = useTranslate();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleActionClick = async (action: string, params?: any) => {
        try {
            await createDeviceActions(selectedIds, action, params);
            refresh();
        } catch (e) {
            console.log(e);
        }
        handleClose();
    }

    return (
      <Box>
        <Button
          id='basic-button'
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup='true'
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          variant={"outlined"}
          endIcon={<ExpandMoreIcon />}
        >
          {translate("devices.actions.title")}
        </Button>
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <InstallAppsDialog
            onSubmit={(params) => handleActionClick("install", params)}
          />
          <MenuItem onClick={() => handleActionClick("shutdown")}>
            {translate("devices.actions.shutdown")}
          </MenuItem>
          <MenuItem onClick={() => handleActionClick("reboot")}>
            {translate("devices.actions.reboot")}
          </MenuItem>
        </Menu>
      </Box>
    );
}

export default MenuActions;