import React from "react";
import {Layout} from "react-admin";
import CustomAppBar from "./CustomAppBar";
import {CustomMenu} from "./CustomMenu";
import {Container} from "@mui/material";
import CGUOnLoad from "../CGU/CGUOnLoad";

const CustomLayout = ({children, ...props}) => (
    <Layout
        {...props}
        sx={{
            "& .RaLayout-content": {
                pt: 4,
                pb: 4,
            },
        }}
        appBar={CustomAppBar}
        menu={CustomMenu}
    >
        <Container maxWidth={'lg'}>
            <CGUOnLoad />
            {children}
        </Container>
    </Layout>
);

export default CustomLayout;
