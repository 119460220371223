import { IResource } from "../models/resource";
import { IAPIRole, IRole, RoleID } from "../models/role";

export const hasAccess = (
  { name, crudType }: IResource,
  permissions: RoleID[]
): boolean => {
  const permissionMap: Record<
    string,
    string[] | { show: string[]; edit: string[]; default: string[] }
  > = {
    experiences: ["admin", "contentCreator", "adminOrga"],
    licenses: ["admin"],
    organizations: {
      show: ["admin", "adminOrga", "contentCreator"],
      edit: ["admin", "adminOrga"],
      default: ["admin"],
    },
    "experience-groups": ["admin"],
    users: ["admin", "adminOrga"],
    "service-licenses": ["admin"],
    "home/updates": ["admin"],
    profile: [],
    tags: ["admin"],
    devices: ["admin"],
    "": [],
  };

  const resourcePermissions = permissionMap[name];
  if (!resourcePermissions) return false;

  if (Array.isArray(resourcePermissions)) {
    return (
      resourcePermissions.length === 0 ||
      resourcePermissions.some((role) => permissions.includes(role as RoleID))
    );
  } else {
    return (
      resourcePermissions[crudType] || resourcePermissions["default"]
    ).some((role) => permissions.includes(role));
  }
};

export const formatRolesForEdit = (roles: IAPIRole[]): IRole[] => {
  return roles.map((role) => ({
    id: role.name,
    name: getRoleDisplayName(role.name),
  }));
};

export const formatRolesForList = (roles: IAPIRole[]) => {
  return roles.map((role) => getRoleDisplayName(role.name));
};

const getRoleDisplayName = (roleID: RoleID) => {
  switch (roleID) {
    case "normal":
      return "Normal";
    case "admin":
      return "Administrateur";
    case "adminOrga":
      return "Administrateur d'Organisation";
  }
};
