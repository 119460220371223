import {
  Autocomplete,
  TextField
} from "@mui/material";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { APIURL } from "../../global/global";

interface Organization {
  id: number;
  name: string;
}

const getOrganizations = async ({ search = "", limit = 20 }) => {
  const { data } = await axios.get(`${APIURL}/organizations`, {
    params: { search, limit },
  });
  return data;
};

interface OrganizationAutocompleteProps {
  className?: string;
  defaultValue?: Organization;
  onChange?: (event, value: Organization | null) => void;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
}

const OrganizationAutocomplete: React.FC<OrganizationAutocompleteProps> = ({
  onChange,
  defaultValue,
  className,
  error = false,
  disabled = false,
  required = false,
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly Organization[]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [tmp, setTmp] = useState("");

  const fetchOrganizations = useCallback(async () => {
    setLoading(true);
    const organizations = await getOrganizations({ search });
    setLoading(false);
    setOptions(organizations);
  }, [search]);

  useEffect(() => {
    fetchOrganizations();
  }, [search, fetchOrganizations]);

  const handleOpen = () => {
    setOpen(true);
    fetchOrganizations();
  };

  const handleClose = () => {
    setOpen(false);
    setOptions([]);
  };

  const handleChange = (event) => setTmp(event.target.value);

  useEffect(() => {
    const timeoutId = setTimeout(() => setSearch(tmp), 300);
    return () => clearTimeout(timeoutId);
  }, [tmp]);

  return (
    <Autocomplete
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      defaultValue={defaultValue}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      className={className}
      readOnly={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ m: 0 }}
          error={error}
          required={required}
          onChange={handleChange}
        />
      )}
      filterOptions={(x) => x}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export default OrganizationAutocomplete;
