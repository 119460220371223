import {Create} from "react-admin";
import ExperienceForm from "./Form/ExperienceForm";
import useExperienceTransform from "../../hooks/useExperienceTransform";
import CustomLinearProgress from "../Layout/Progresses/CustomProgress";

const ExperienceCreate = () => {
    const {transform, progress} = useExperienceTransform();

    return (
        <Create transform={transform} redirect={"/experiences"}>
            <ExperienceForm isUploading={!!progress}/>
            {progress && <CustomLinearProgress value={progress}/>}
        </Create>
    );
};

export default ExperienceCreate;
