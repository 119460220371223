import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import {ServiceLicenseStatus} from "../../../models/service-license";
import {Tooltip} from "@mui/material";
import {useTranslate} from "react-admin";

const StatusIcon: React.FC<{ status?: ServiceLicenseStatus }> = ({status}) => {
    const translate = useTranslate();

    const color = status === "active" ? "#33d033" :
        status === "expiring" ? "#f0a408" :
            status === "expired" ? "#ff0000" : "grey";

    const sx = {
        padding: "1px",
        border: "1px solid #dddddd",
        borderRadius: "1em",
        background: "#ffffff",
        color
    };

    const tooltipTitle = status === "active" ?
        translate("status.active") : status === "expiring" ?
            translate("status.expiring") : status === "expired" ?
                translate("status.expired") : translate("status.undefined");

    return <Tooltip title={tooltipTitle}>
        <CircleIcon sx={sx}/>
    </Tooltip>;
}

export default StatusIcon;