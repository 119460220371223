import { FunctionField, useTranslate } from "react-admin";

const ActionField = ({source, label}) => {
    const translate = useTranslate();
    return <FunctionField
        source={source}
        label={label}
        render={(record: any) => translate(`devices.actions.${record.action}`)}
    />;
}

export default ActionField;