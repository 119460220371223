import {
  AutocompleteInput,
  Loading,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
  usePermissions,
  useRedirect,
  useTranslate,
} from "react-admin";
import { hasAccess } from "../../../global/permissions";
import CustomSimpleToolbar from "../../Layout/CustomSimpleToolbar";
import { Container } from "@mui/material";

const ExperienceGroupForm = () => {
  const redirect = useRedirect();
  const { permissions } = usePermissions();
  const translate = useTranslate();

  const validateForm = (values) => {
    const errors = {};

    if (!values.name) {
      errors["name"] = translate("ra.validation.required");
    }

    return errors;
  };

  if (!permissions) return <Loading />;

  if (!hasAccess({ name: "experience-groups", crudType: "edit" }, permissions))
    redirect("/");

  return (
    <Container maxWidth={"md"}>
      <SimpleForm validate={validateForm} toolbar={<CustomSimpleToolbar />}>
        <TextInput source='name' label={translate("misc.name")} />

        <ReferenceArrayInput
          source='experience_ids'
          reference='experiences'
          perPage={1000}
          children={
            <AutocompleteInput
              label={translate("resources.experiences")}
              sx={{ minWidth: "20em" }}
              multiple
              inputText={(option) => option.name}
              matchSuggestion={(filterValue, suggestion) =>
                suggestion.name
                  .toLowerCase()
                  .includes(filterValue.toLowerCase())
              }
            />
          }
        />
      </SimpleForm>
    </Container>
  );
};

export default ExperienceGroupForm;
