import * as React from "react";
import { useGetIdentity, useLocaleState, useTranslate } from "react-admin";
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Menu,
  Button,
  Divider,
  Stack,
  styled,
  MenuProps,
  alpha,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { Link, useLocation } from "react-router-dom";
import authProvider from "../../data-providers/auth-provider";
import LogoutIcon from "@mui/icons-material/Logout";
import { i18nProvider } from "../../i18n/i18nProvider";
import LanguageIcon from "@mui/icons-material/Language";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { theme } from "../../global/styles";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      padding: "12px 16px",
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[300],
    }),
  },
}));

const Welcome: React.FC<any> = () => {
  const translate = useTranslate();
  return (
    <Typography
      variant='subtitle1'
      color={theme.palette.grey[600]}
      px={2}
      pt={1}
    >
      {translate("misc.welcome")}
    </Typography>
  );
};

const ProfileMenuItem: React.FC<any> = () => {
  const translate = useTranslate();
  return (
    <MenuItem component={Link} to='/profile'>
      <ListItemIcon>
        <PersonIcon fontSize='small' />
      </ListItemIcon>
      <ListItemText>{translate("resources.my_account")}</ListItemText>
    </MenuItem>
  );
};

const LangMenuItem: React.FC<any> = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [locale, setLocale] = useLocaleState();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getLocaleText = (() => {
    return (
      i18nProvider.getLocales().find((language) => language.locale === locale)
        .name || ""
    );
  })();

  return (
    <MenuItem>
      <ListItemIcon>
        <LanguageIcon fontSize='small' />
      </ListItemIcon>
      <ListItemText>
        <Typography onClick={handleClick}>{getLocaleText}</Typography>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {i18nProvider.getLocales().map((l) => (
            <MenuItem
              selected={locale === l.locale}
              onClick={() => setLocale(l.locale)}
            >
              {l.name}
            </MenuItem>
          ))}
        </Menu>
      </ListItemText>
    </MenuItem>
  );
};

const LogoutMenuItem: React.FC = () => {
  const translate = useTranslate();
  const logout = async () => {
    await authProvider.logout();
    window.location.href = "/";
  };
  return (
    <MenuItem onClick={logout}>
      <ListItemIcon>
        <LogoutIcon fontSize='small' />
      </ListItemIcon>
      <ListItemText>{translate("login.logout")}</ListItemText>
    </MenuItem>
  );
};

const CustomMenuItem = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const menuRef = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    setAnchorEl(null);
  }, [location]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data, isPending, error } = useGetIdentity();

  if (isPending || error) return null;

  const { first_name, last_name } = data;

  return (
    <Stack direction={"row"} alignItems={"center"} ml={1}>
      <Button
        variant='contained'
        color='info'
        onClick={handleClick}
        startIcon={<AccountCircleIcon fontSize='large' />}
        endIcon={<ExpandMoreIcon />}
      >
        {first_name} {last_name}
      </Button>
      <StyledMenu
        id='menu-appbar'
        ref={menuRef}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Welcome />
        <ProfileMenuItem />
        <LangMenuItem />
        <Divider />
        <LogoutMenuItem />
      </StyledMenu>
    </Stack>
  );
};

export default CustomMenuItem;
