import axios from "axios";

export default {
  fetchPosts: async () => {
    try {
      const res = await axios.get(`https://univrstudio.com/wp-json/wp/v2/posts?_fields=id,title,link,featured_media&per_page=3&categories=656`);
  
      return res.data;
    } catch (error) {
      console.log(error);
  
      return {error: error.message};
    }
  },
  fetchThumbnail: async (mediaId: number) => {
    try {
      const res = await axios.get(`https://univrstudio.com/wp-json/wp/v2/media/${mediaId}`);
  
      return res.data.source_url;
    } catch (error) {
      console.log(error);
  
      return {error: error.message};
    }
  },
}