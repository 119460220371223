import React from "react";
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  Loading,
  TopToolbar,
  usePermissions,
  useRecordContext,
  useRedirect,
  useTranslate,
} from "react-admin";
import { Link, useParams } from "react-router-dom";
import { hasAccess } from "../../global/permissions";
import ListLayout from "../Layout/ListLayout";
import DefaultFilter from "../Layout/Filters/DefaultFilter";
import moment from "moment";
import { Button, Chip, Stack } from "@mui/material";
import CustomCreateButton from "../Layout/Buttons/CustomCreateButton";
import EditIcon from "@mui/icons-material/Edit";
import StatusIcon from "../Layout/Icons/StatusIcon";

const EditButton: React.FC<any> = () => {
  const record = useRecordContext();
  const { id: organizationId } = useParams();
  const translate = useTranslate();

  return (
    <Button
      variant='contained'
      component={Link}
      size='small'
      to={`/organizations/${organizationId}/service-licenses/${record?.id}`}
      startIcon={<EditIcon />}
    >
      {translate("ra.action.edit")}
    </Button>
  );
};

const Actions: React.FC<any> = () => {
  const { id: organization_id } = useParams();
  const translate = useTranslate();
  return (
    <TopToolbar
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        gap: "2rem",
      }}
    >
      <CustomCreateButton
        to={`/organizations/${organization_id}/service-licenses/create`}
        label={translate("service-licenses.create")}
      />
    </TopToolbar>
  );
};

const ServiceLicenseList: React.FC = () => {
  const organization = JSON.parse(localStorage.getItem("organization"));
  const redirect = useRedirect();
  const { permissions } = usePermissions();
  const { id: parentResourceId } = useParams();
  const [record, setRecord] = React.useState(null);
  const translate = useTranslate();

  if (!organization || !permissions) return <Loading />;

  if (!hasAccess({ name: "licenses", crudType: "list" }, permissions))
    redirect("/");

  const filter = { organization_id: parentResourceId };

  const renderEndDate = (record: any) => {
    if (record.type === "unlimited") return "-";
    return moment(record.end_date).format("DD/MM/YYYY");
  };

  const renderType = (record: any) => {
    return translate(`service-licenses.types.${record.type}`);
  };

  const renderServices: any = (record: any) => {
    if (!record.services) return "-";
    return (
      <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
        {record.services.map((s: string) => (
          <Chip label={translate(`service-licenses.services.${s}`)} />
        ))}
      </Stack>
    );
  };

  const renderStatus = (record: any) => <StatusIcon status={record.status} />;

  return (
    <ListLayout title={translate("resources.service-licenses")}>
      <List
        resource='service-licenses'
        filter={filter}
        filters={<DefaultFilter hasStatusFilter />}
        sort={{ field: "updated_at", order: "DESC" }}
        actions={<Actions />}
        emptyWhileLoading
        empty={false}
      >
        <Datagrid bulkActionButtons={false}>
          <DateField source='start_date' label={translate("misc.start_date")} />
          <FunctionField
            label={translate("misc.end_date")}
            render={renderEndDate}
            sortBy='end_date'
          />
          <FunctionField
            source='type'
            label={translate("misc.type")}
            render={renderType}
          />
          <FunctionField
            source='services'
            label={translate("service-licenses.services.index")}
            render={renderServices}
            sortable={false}
          />
          <FunctionField
            source='status'
            label={translate("service-licenses.status")}
            render={renderStatus}
            sortable={false}
          />
          <EditButton />
        </Datagrid>
      </List>
    </ListLayout>
  );
};

export default ServiceLicenseList;
