import {APIURL, HEADERS} from "../global/global";
import {RoleID} from "../models/role";

/* eslint-disable import/no-anonymous-default-export */
export default {
    login: async (loginForm) => {
        loginForm.service = "manager";

        // login
        const request = new Request(`${APIURL}/auth/login`, {
            method: "POST",
            body: JSON.stringify(loginForm),
            headers: new Headers({
                "Content-Type": "application/json",
                locale: "fr",
            }),
        });

        const response = await fetch(request);

        if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
        }

        const {
            access_token,
            roles,
            user,
            organization,
        } = await response.json();

        if (!hasAccessToManager(roles)) throw new Error("No access to UniVR Manager.");

        localStorage.setItem(
            "token",
            JSON.stringify({access_token})
        );
        localStorage.setItem("roles", JSON.stringify(roles));
        localStorage.setItem("me", JSON.stringify(user));
        localStorage.setItem("organization", JSON.stringify(organization));
    },
    logout: async () => {
        try {
            const {access_token} = JSON.parse(localStorage.getItem("token"));

            const request = new Request(`${APIURL}/auth/logout`, {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    locale: "fr",
                    Authorization: "Bearer " + access_token,
                }),
            });

            await fetch(request);
            localStorage.clear();
            HEADERS.delete("Authorization");
        } catch (e) {
            console.log(e);
        }
    },
    checkAuth: () => {
        return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
    },
    checkError: (error) => {
        const status = error.status;
        const message = error.message;

        if (message?.includes("Unauthenticated")) {
            return Promise.reject();
        }

        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getPermissions: () => {
        if (localStorage.getItem("roles")) {
            const roles = JSON.parse(localStorage.getItem("roles"));
            return Promise.resolve(roles);
        }

        return Promise.resolve(null);
    },
    getIdentity: () => {
        try {
            const {id, first_name, last_name, email, cgu_accepted_at} = JSON.parse(
                localStorage.getItem("me")
            );
            const organization = JSON.parse(localStorage.getItem("organization"));
            const roles = JSON.parse(localStorage.getItem("roles"));
            
            return Promise.resolve({ id, first_name, last_name, email, organization, roles, cgu_accepted_at });
        } catch (error) {
            return Promise.reject(error);
        }
    },
};

const hasAccessToManager = (roles: RoleID[]) => {
    return roles.includes("admin") || roles.includes("adminOrga") || roles.includes("contentCreator");
}
