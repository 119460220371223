import { Skeleton } from "@mui/material";

const SkeletonList = () => {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Skeleton variant="text" width={200} height={54} />
        <Skeleton variant="text" width={200} height={54} />
      </div>
      <div style={{}}>
        <Skeleton variant="rounded" width="100%" height="87vh" />
      </div>
    </div>
  );
};

export default SkeletonList;
