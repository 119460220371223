import { Create } from "react-admin";
import UserForm from "./UserForm";
import { useParams } from "react-router-dom";

const UserCreate = (props) => {
  const { id: organizationId } = useParams();

  return (
    <Create
      resource='users'
      redirect={`/organizations/${organizationId}/users`}
    >
      <UserForm {...props} />
    </Create>
  );
};

export default UserCreate;
