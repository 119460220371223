import React from 'react';
import {CreateButtonProps, TopToolbar} from "react-admin";
import CustomCreateButton from "./Buttons/CustomCreateButton";

interface CustomActionBarProps {
    createButtonProps?: CreateButtonProps;
}

const CustomActionBar: React.FC<CustomActionBarProps> = (props) => (
    <TopToolbar sx={{alignItems: 'center'}}>
        <CustomCreateButton {...props.createButtonProps} />
    </TopToolbar>
)

export default CustomActionBar;
