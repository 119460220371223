import { makeStyles } from "@mui/styles";
import { MenuItemLink } from "react-admin"
import { useLocation } from "react-router-dom";
import { theme } from "../../global/styles";

const useStyles = makeStyles({
  menuItem: {
    margin: 0,
    padding: "8px 16px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "1px",
    fontSize: ".9rem",
  },
});

export const CustomMenuItemLink = ({ key, to, primaryText, leftIcon }) => {
  const classes = useStyles();
  const location = useLocation();

  const isActive = (currentPath: string, toLink: string): boolean => {
    return currentPath.replace(/\d+/g, '*') === toLink.replace(/\d+/g, '*');
  };

  return (
    <MenuItemLink
      className={classes.menuItem}
      key={key}
      to={to}
      primaryText={primaryText}
      leftIcon={leftIcon}
      sx={isActive(location.pathname, to) ? {
        color: `${theme.palette.primary.main} !important`,
        '& .RaMenuItemLink-icon': {
          color: `${theme.palette.primary.main} !important`,
        }
      } : {}}
    />
  )
}