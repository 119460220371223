import { TextField } from "@mui/material";
import React from "react";
import { useInput } from "react-admin";
import randomColor from "randomcolor";

interface ColorPickerProps {
  source: string;
  label: string;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ source, label }) => {
  const { id, field, fieldState } = useInput({ source });
  const defaultColor = randomColor({
    luminosity: "bright",
    format: "hex",
    hue: "random",
    alpha: 1,
  });

  return (
    <TextField
      {...field}
      id={id}
      label={label}
      sx={{ width: "6em" }}
      type='color'
      value={field.value || defaultColor}
    />
  );
};

export default ColorPicker;
