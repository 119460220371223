import {
  Autocomplete,
  Chip,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useTranslate } from "react-admin";
import { theme } from "../../global/styles";
import { rolesColors } from "../../models/role";
import PasswordField from "../Layout/Fields/PasswordField";
import moment from "moment";
import DoneIcon from "@mui/icons-material/Done";
import CGUDialog from "../CGU/CGUDialog";

const useStyles = makeStyles({
  itemTitle: {
    color: "#919191",
    width: "10em",
  },
  itemValue: {
    flex: "1",
  },
  chip: {
    marginRight: "1em",
  },
  save: {
    width: "fit-content",
    marginTop: "4em",
  },
  list: {
    marginTop: "1em",
  },
});

interface BaseItemProps {
  title: string;
  required: boolean;
  edit: boolean;
  children: React.ReactNode;
}

const BaseItem: React.FC<BaseItemProps> = ({
  title,
  required,
  edit,
  children,
}) => {
  const classes = useStyles();
  return (
    <Stack direction='row' alignItems='center' gap={2}>
      <Typography className={classes.itemTitle}>
        {title}
        {required && edit && "*"}
      </Typography>
      {children}
    </Stack>
  );
};

interface ItemProps {
  name?: string;
  value: string | any;
  valueKey?: string;
  title: string;
  onChange?: (...args: any[]) => void;
  edit?: boolean;
  type?: string;
  required?: boolean;
  field?: React.FC<any>;
  error?: boolean;
  disabled?: boolean;
}

const Item: React.FC<ItemProps> = ({
  name,
  title,
  value,
  valueKey = null,
  onChange = null,
  edit = false,
  type = "text",
  required = false,
  error = false,
  disabled = false,
  field: Field = TextField,
}) => {
  const classes = useStyles();
  const DefinitiveField = type === "password" ? PasswordField : Field;

  return (
    <BaseItem title={title} required={required} edit={edit}>
      {!edit ? (
        <Typography className={classes.itemValue}>
          {valueKey ? value[valueKey] : value}
        </Typography>
      ) : (
        <DefinitiveField
          name={name}
          className={classes.itemValue}
          sx={{ m: 0 }}
          defaultValue={value}
          variant='outlined'
          onChange={onChange}
          disabled={disabled}
          required={required}
          error={error}
          type={type}
        />
      )}
    </BaseItem>
  );
};

const CGUItem = ({
  title,
  edit = false,
  required = false,
  cgu_accepted_at,
  id,
}) => {
  const translate = useTranslate();

  return (
    <BaseItem title={title} required={required} edit={edit}>
      <Stack
        direction={"row"}
        gap={2}
        flexWrap={"wrap"}
        alignItems={"center"}
      >
        <CGUDialog
          userId={id as number}
          cguAlreadyAccepted={!!cgu_accepted_at} // use cgu_accepted_at value as boolean
        />
        {cgu_accepted_at && (
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            <Typography variant={"body2"} color={"green"} display={"flex"} alignItems={"center"}>
              <DoneIcon />
              {translate("profile.cgu_accepted_on")}{" "}
              {moment(cgu_accepted_at).format(
                "DD/MM/YYYY"
              )}
            </Typography>
          </Stack>
        )}
      </Stack>
    </BaseItem>
  );
};

const RolesItem: React.FC<ItemProps> = ({
  name,
  title,
  value,
  onChange = null,
  edit = false,
  required = false,
  error = false,
  disabled = false,
}) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <BaseItem title={title} required={required} edit={edit}>
      {edit ? (
        <Autocomplete
          multiple
          id='tags-outlined'
          options={Object.keys(rolesColors)}
          getOptionLabel={(option) => translate(`roles.${option}`)}
          defaultValue={value}
          onChange={(_, value) => onChange(value)}
          fullWidth
          className={classes.itemValue}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              disabled={disabled}
              sx={{ m: 0 }}
              name={name}
              variant='outlined'
              required={required}
              error={error}
            />
          )}
        />
      ) : (
        <Stack direction='row' gap={2} flexWrap='wrap'>
          {value.map((role) => (
            <Chip
              key={role}
              label={translate(`roles.${role}`)}
              sx={{
                "& span": {
                  color: theme.palette[rolesColors[role]].main,
                },
              }}
            />
          ))}
        </Stack>
      )}
    </BaseItem>
  );
};

const ComingSoonItem: React.FC<Omit<ItemProps, 'value'>> = ({ title, edit }) => {
    const translate = useTranslate();
    const classes = useStyles();
    return (
        <BaseItem title={title} required={false} edit={edit}>
            {!edit && (
                <Typography className={classes.itemValue} color={"gray"}>
                    {translate("misc.coming_soon")}
                </Typography>
            )}
            {edit && (
                <Select value={0} disabled className={classes.itemValue}>
                    <MenuItem value={0}>{translate("misc.coming_soon")}</MenuItem>
                </Select>
            )}
        </BaseItem>
    );
};

export { Item, RolesItem, ComingSoonItem, CGUItem };
