import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
    (config) => {
        config = handleToken(config);
        return config;
    },
    (error) => Promise.reject(error)
);

const handleToken = (config: any) => {
    try {
        if (config.headers.Authorization) return config;
        const {access_token: token} = JSON.parse(localStorage.getItem("token"));
        config.headers.Authorization = `Bearer ${token}`;
    } catch (e) {
    }
    return config;
};