import {LinearProgress, Stack, Typography} from "@mui/material";
import {theme} from "../../../global/styles";

export default function CustomLinearProgress({value}) {
    return <Stack padding="0em 4em 3em 4em" gap={2}>
        <LinearProgress variant="determinate" value={value} sx={{
            height: "1em",
            borderRadius: "16px",
            backgroundColor: theme.palette.background.default,
        }}/>
        <Typography color={"GrayText"} alignSelf={"center"}>
            Publication en cours, ne quittez pas la page...
        </Typography>
    </Stack>;
}