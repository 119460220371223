import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import postsService from "../../services/api/posts.service";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslate } from "react-admin";

const useStyles = makeStyles({
  container: {
    height: '100%',
  },
  content: {
    textAlign: 'center',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  image: {
    width: '100%',
    height: 200,
    objectFit: 'cover',
    borderRadius: '1rem',
    transition: 'transform 0.25s ease',
    "&:hover": {
      transform: 'scale(1.1)',
    },
  }
});

export const DashboardNewsCard = ({ post }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [thumbnailUrl, setThumbnailUrl] = useState('');

  const decodeHtml = (html: string): string => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }  

  useEffect(() => {
    const fetchThumbnail = async () => {
      const res = await postsService.fetchThumbnail(post.featured_media);
  
      if (res.error) console.error(res.error);
      else setThumbnailUrl(res);
    };

    (async () => {
      try {
        fetchThumbnail();
      } catch (e) {
        console.error("Erreur lors de la récupération des données", e);
      }
    })();
  }, []);

  return (
    <Grid item sm={12} md={6} lg={4}>
      <Card className={classes.container}>
        <CardContent className={classes.content}>
          <Typography fontWeight={600} mb={2}>{decodeHtml(post.title.rendered)}</Typography>
          <Box>
            <Box overflow="hidden" borderRadius={1}>
              <Link to={post.link} target="_blank">
                <img className={classes.image} src={thumbnailUrl} alt="placeholder" />
              </Link>
            </Box>
            <Button
              sx={{marginTop: 2, alignSelf: 'center'}}
              color={"primary"}
              size="small"
              variant="contained"
              component={Link}
              to={post.link}
              target="_blank"
            >
              {translate('misc.read')}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  )
}