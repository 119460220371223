import {Divider} from "@mui/material";
import {
  ArrayInput,
  BooleanInput,
  FormDataConsumer,
  FormTab,
  ImageField,
  ImageInput,
  NumberInput,
  SelectArrayInput,
  SimpleFormIterator,
  TextInput,
  useRecordContext,
} from "react-admin";

const ModulesFormTab = (props) => {
  const DisplayNameField = () => {
    const record = useRecordContext();
    return <span>{record?.display_name}</span>;
  };

  return (
    <FormTab {...props} label='Modules'>
      <FormDataConsumer>
        {({scopedFormData, formData, ...rest}) => (
          <BooleanInput
            source='bypass_modules'
            label='Composer config.json à la main'
            defaultValue={formData?.modules?.length === 0} 
            helperText="Cette checkbox permet de conserver le fichier de 'config.json', composé manuellement."
          />
        )}
      </FormDataConsumer>

      <Divider flexItem sx={{ my: 2 }} />

      <ArrayInput source='modules' label={false}>
        <SimpleFormIterator>
          {/* hidden field step */}
          <NumberInput
            source='step'
            label='Étape'
            style={{ display: "none" }}
            defaultValue={0}
          />

          <TextInput
            source='slug'
            label="Slug (ID dans l'expérience)"
            required
          />

          <TextInput source='display_name' label='Nom' required />

          <FormDataConsumer>
            {({scopedFormData, formData, ...rest}) =>
              scopedFormData?.slug &&
              formData?.modules?.length > 1 && (
                <SelectArrayInput
                  {...rest}
                  source={"parent_slugs"}
                  label='Modules Parents'
                  optionText={<DisplayNameField />}
                  optionValue='slug'
                  size='medium'
                  choices={formData.modules.filter(
                    (m) =>
                      // no self, has a name, remove children (to avoid infinite loop)
                      m?.slug !== scopedFormData?.slug &&
                      m?.display_name !== undefined &&
                      !m?.parent_slugs?.find(
                        (ps) => ps === scopedFormData?.slug
                      )
                  )}
                  sx={{
                    maxWidth: "300px",
                    width: "100%",
                  }}
                />
              )
            }
          </FormDataConsumer>

          <BooleanInput source='active' label='Actif ?' defaultValue={true} />

          <ImageInput source='images' label='Image' helperText='Dimension : 644x1100' accept={{'image/*': []}}>
            <>
              <ImageField source='public_display_link' title='id' />
              <ImageField source='src' title='id' />
            </>
          </ImageInput>
        </SimpleFormIterator>
      </ArrayInput>
    </FormTab>
  );
};

export default ModulesFormTab;
