import {
    Datagrid,
    DateField,
    List,
    TextField,
    useListContext,
    useRecordContext,
    useRefresh,
    useTranslate,
    useUnselectAll
} from "react-admin";
import React, {useEffect} from "react";
import {Button} from "@mui/material";
import {deleteManyDeviceActions} from "../../../services/api/devices.service";
import DeviceActionParamsField from "../Fields/DeviceActionParamsField";
import StatusField from "../Fields/StatusField";
import ActionField from "../Fields/ActionField";

const BulkActionButtons = () => {
    const {selectedIds} = useListContext();
    const record = useRecordContext();
    const deviceId = record.id as string;
    const unselectAll = useUnselectAll(`devices/${deviceId}/actions`);
    const refresh = useRefresh();

    console.log(selectedIds);

    useEffect(() => {
        return () => unselectAll();
    }, [unselectAll]);

    const handleDelete = async () => {
        try {
            await deleteManyDeviceActions(deviceId, selectedIds);
            unselectAll();
            refresh();
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <Button
            variant={"outlined"}
            size={"medium"}
            onClick={handleDelete}
            color={"error"}
        >
            Supprimer
        </Button>
    );
}

interface DeviceActionListProps {
    deviceId: string;
}

const DeviceActionList: React.FC<DeviceActionListProps> = ({deviceId}) => {
    const translate = useTranslate();

    return (
      <List
        exporter={false}
        perPage={10}
        sort={{ field: "created_at", order: "DESC" }}
        resource={`devices/${deviceId}/actions`}
        disableSyncWithLocation
        pagination={false}
      >
        <Datagrid rowClick={false} bulkActionButtons={<BulkActionButtons />}>
          <TextField source='id' label={translate("misc.id")} />
          <ActionField source='action' label={translate("misc.action")} />
          <DeviceActionParamsField
            source='params'
            label={translate("misc.parameters")}
          />
          <DateField source='created_at' label={translate("dates.creation_date")} showTime />
          <StatusField source='status' label={translate("misc.status")} />
        </Datagrid>
      </List>
    );
}

export default DeviceActionList;