import React from "react";
import {SvgIconProps} from "@mui/material";

interface TextWithIconProps {
    text: string;
    icon: React.ElementType<SvgIconProps>;
    color: string;
}

const TextWithIcon: React.FC<TextWithIconProps> = ({text, icon: Icon, color}) => {
    return (
        <span style={{color}}>
            <Icon style={{verticalAlign: 'middle', marginRight: 4}}/>
            {text}
        </span>
    );
};

export default TextWithIcon;