import axios from "axios";

export const createDeviceActions = async (deviceIds: string[], action: string, params?: any) => {
    return axios.post(`/devices/actions`, {
        device_ids: deviceIds,
        action: action,
        params: params
    });
}

export const deleteDeviceActions = async (deviceId: string, statuses: string[] = ["done", "error"]) => {
    return axios.delete(`/devices/${deviceId}/actions`, {
        params: {statuses}
    });
}

export const deleteManyDevices = async (deviceIds: string[]) => {
    return axios.delete(`/devices`, {
        params: {ids: deviceIds}
    });
}

export const deleteManyDeviceActions = async (deviceId: string, actionIds: number[]) => {
    return axios.delete(`/devices/${deviceId}/actions`, {
        params: {action_ids: actionIds}
    });
}
