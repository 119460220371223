import React from "react";
import {RichTextInput} from "ra-input-rich-text";
import {
    AutocompleteArrayInput,
    BooleanInput,
    DeleteWithConfirmButton,
    FormDataConsumer,
    FormTab,
    ReferenceArrayInput,
    required,
    SaveButton,
    SelectInput,
    TabbedForm,
    TabbedFormTabs,
    TextInput,
    Toolbar,
    usePermissions,
    useRecordContext,
    useRedirect,
    useTranslate,
} from "react-admin";
import {FilesFormTab} from "./Tabs/Files";
import {VersionFormTab} from "./Tabs/Version";
import {hasAccess} from "../../../global/permissions";
import {TagsFormTab} from "./Tabs/Tags";
import ModulesFormTab from "./Tabs/Modules";
import ColorPicker from "../../Layout/Inputs/ColorPicker";
import ConfigFormTab from "./Tabs/Config";
import dataProvider from "../../../data-providers/data-provider";
import ManagerShareFormTab from "./Tabs/ManagerShare";
import ManagerTechnicalFormTab from "./Tabs/ManagerTechnical";
import ManagerGeneralFormTab from "./Tabs/ManagerGeneral";
import StepperManager from "./StepperManager";
import {Box, Button, Container, Stack, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import SkeletonForm from "../../Loading/SkeletonForm";
import { theme } from "../../../global/styles";

export const NameField = () => {
    const record = useRecordContext();
    return <span>{record.name}</span>;
};

const ExperienceToolbar = ({isUploading}: { isUploading?: boolean }) => {
    const record = useRecordContext();
    return (
        <Toolbar>
            <Stack direction="row" gap={2} justifyContent={"flex-end"} py={2} width={"100%"}>
                {record && <DeleteWithConfirmButton size="medium" icon={null} disabled={isUploading} confirmTitle={`Suppression de l'expérience ${record.name}`}/>}
                <SaveButton icon={null} alwaysEnable={!isUploading}/>
            </Stack>
        </Toolbar>
    );
};

const ExperienceForm = (props) => {
    const redirect = useRedirect();
    const {permissions} = usePermissions();
    const {id: organizationId} = JSON.parse(
        localStorage.getItem("organization")
    );
    // get record
    const record = useRecordContext();
    const [tags, setTags] = React.useState([]);
    const [experienceGroups, setExperienceGroups] = React.useState([]);
    const translate = useTranslate();
    const {isUploading} = props;

    /**
     * Validate data.
     * @param values
     */
    const validateForm = (values) => {
        const errors = {};

        // name
        if (!values.name) {
            errors["name"] = translate("ra.validation.required");
        }

        // description
        if (!values.description) {
            errors["description"] = translate("ra.validation.required");
        }

        return errors;
    };

    const steps = [translate("experiences.steps.general"), translate("experiences.steps.technical"), translate("experiences.steps.share")];
    const componentsSteps = [
        <ManagerGeneralFormTab/>,
        <ManagerTechnicalFormTab tags={tags}/>,
        <ManagerShareFormTab />,
    ];

    React.useEffect(() => {
        dataProvider
            .getList("tags")
            .then(({data}) => {
                setTags(data);
            })
            .catch((err) => console.log(err));

        dataProvider
            .getList("experience-groups")
            .then(({data}) => {
                setExperienceGroups(data);
            })
            .catch((err) => console.log(err));
    }, []);

    const displayPermission = permissions?.includes("admin");

    const [displayLegacyForm, setDisplayLegacyForm] =
        React.useState<boolean>(displayPermission);

    if (!permissions)
        return (
            <div>
                <SkeletonForm/>
            </div>
        );

    if (!hasAccess({name: "experiences", crudType: "edit"}, permissions))
        redirect("/");

    return (
        <div style={{position: 'relative'}}>
            <Box position="absolute" top={0} left={0} p={2}>
                <Button sx={{color: theme.palette.grey[600]}} href={`/experiences`}>{translate('misc.cancel')}</Button>
            </Box>
            {displayPermission && (
                <Stack alignItems={'center'} p={2}>
                    <ToggleButtonGroup
                        color="primary"
                        value={displayLegacyForm ? "advanced" : "simplified"}
                        exclusive
                        onChange={(event, newValue) => setDisplayLegacyForm(newValue === "advanced")}
                        size={"small"}
                    >
                        <ToggleButton
                            value="advanced">{translate("experiences.steps.advanced_interface")}</ToggleButton>
                        <ToggleButton
                            value="simplified">{translate("experiences.steps.simplified_interface")}</ToggleButton>
                    </ToggleButtonGroup>
                </Stack>
            )}
            {displayLegacyForm ? (
                <Container maxWidth={"md"}>
                    <TabbedForm
                        validate={validateForm}
                        tabs={
                            <TabbedFormTabs
                                variant="scrollable"
                                scrollButtons="auto"
                                style={{maxWidth: "none"}}
                            />
                        }
                        warnWhenUnsavedChanges={false}
                        sanitizeEmptyValues
                        toolbar={<ExperienceToolbar isUploading={isUploading}/>}
                    >
                        <FormTab label="Général">
                            {/* hidden field with organization id */}
                            <TextInput
                                source="organization_id"
                                defaultValue={organizationId}
                                style={{display: "none"}}
                            />

                            <TextInput source="name" label="Titre" style={{width: "25em"}}/>

                            <TextInput source="slug" label="Slug" style={{width: "25em"}}/>

                            <SelectInput
                                source="type"
                                defaultValue="build"
                                choices={[
                                    {id: "build", name: "Expérience avec build"},
                                    {id: "browser", name: "Expérience sur navigateur"},
                                    {
                                        id: "path",
                                        name: "Expérience avec chemin vers un exécutable",
                                    },
                                    {id: "video", name: "Vidéo 360°"},
                                ]}
                                style={{width: "25em"}}
                            />

                            <FormDataConsumer>
                                {({formData, ...rest}) =>
                                    formData.type === "path" && (
                                        <SelectInput
                                            {...rest}
                                            label="Dossier"
                                            source="path_prefix"
                                            defaultValue="build"
                                            validate={[required()]}
                                            emptyText="Chemin absolu"
                                            choices={[
                                                {id: "oculus", name: "Oculus"},
                                                {id: "steam", name: "Steam"},
                                            ]}
                                            style={{width: "25em"}}
                                        />
                                    )
                                }
                            </FormDataConsumer>

                            <FormDataConsumer>
                                {({formData, ...rest}) =>
                                    formData.type === "browser" && (
                                        <TextInput
                                            {...rest}
                                            source="url"
                                            label="URL"
                                            placeholder="http://mon_experience.com"
                                            style={{width: "25em"}}
                                        />
                                    )
                                }
                            </FormDataConsumer>

                            <ReferenceArrayInput
                                source="group_ids"
                                reference="experience-groups"
                                perPage={9999}
                            >
                                <AutocompleteArrayInput
                                    label="Groupes d'expériences"
                                    inputText={(option) => option.name}
                                    matchSuggestion={(filterValue, suggestion) =>
                                        suggestion.name
                                            .toLowerCase()
                                            .includes(filterValue.toLowerCase())
                                    }
                                    variant="outlined"
                                    size="small"
                                    sx={{width: "100%", maxWidth: "25em"}}
                                    disableCloseOnSelect
                                    blurOnSelect={false}
                                />
                            </ReferenceArrayInput>

                            <RichTextInput source="description" label="Description"/>
                        </FormTab>

                        <TagsFormTab {...props} tags={tags}/>

                        <FormTab label="Options">
                            <BooleanInput
                                source="is_public"
                                label="Disponible pour tous les utilisateurs (sans licence)"
                            />
                            <BooleanInput
                                source="display_in_discover"
                                label='Visible dans la partie "A découvrir"'
                            />
                            <ColorPicker source="color" label="Couleur"/>
                            <TextInput
                                source="references_url"
                                label="URL des références"
                                sx={{width: "20em"}}
                            />
                        </FormTab>

                        <FilesFormTab {...props} />

                        <ModulesFormTab {...props} />

                        <ConfigFormTab {...props} />

                        <VersionFormTab {...props} />
                    </TabbedForm>
                </Container>
            ) : (
                <StepperManager
                    steps={steps}
                    componentsSteps={componentsSteps}
                />
            )}
        </div>
    );
};

export default ExperienceForm;
