import polyglotI18nProvider from 'ra-i18n-polyglot';
import fr from './fr';
import en from './en';
import moment from "moment";
import 'moment/locale/fr';
import {resolveBrowserLocale} from "react-admin";

const translations = {en, fr};

const browserLocale = resolveBrowserLocale() === 'fr' ? 'fr' : 'en';

moment().locale(browserLocale);

export const i18nProvider = polyglotI18nProvider(
    locale => translations[locale] ? translations[locale] : translations.en,
    browserLocale,
    [{locale: 'en', name: 'English'}, {locale: 'fr', name: 'Français'}],
);