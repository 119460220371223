import axios from "axios";
import moment from "moment";

export interface MonthlyUsageTrendsData {
  month: string;
  count: number;
}

export default {
  fetchGlobalInsights: async () => {
    try {
      const token = JSON.parse(localStorage.getItem("token")).access_token;
      const organization = JSON.parse(localStorage.getItem("organization"));
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/insights/global?organization_id=${organization.id}`, {
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      });
  
      return res.data;
    } catch (error) {
      console.log(error);
  
      return {error: error.message};
    }
  },
  getUsageTrends: async (params: any) => {
    try {
      const res = await axios.get("/trends/usage", { params })
      return res.data;
    } catch (error) {
      console.log(error);
  
      return {error: error.message};
    }
  },
  getTopExperiences: async (params: any) => {
    try {
      const res = await axios.get("/trends/top-flop", { params })
      return res.data;
    } catch (error) {
      console.log(error);
  
      return {error: error.message};
    }
  },
  formatUsageTrends: (
    data: MonthlyUsageTrendsData[]
  ): MonthlyUsageTrendsData[] => {
    const res = data.map((item) => ({
      month: moment(item.month)?.format("MMM YY"),
      count: item.count,
    }));
  
    return res;
  },
}