import {useEffect, useState} from "react";
import {useGetIdentity, usePermissions} from "react-admin";
import dataProvider from "../../data-providers/data-provider";
import {IRole} from "../../models/role";
import {DashboardLastNews} from "./DashboardLastNews";
import {redirect} from "react-router-dom";
import {Container, Paper, Stack} from "@mui/material";
import {Welcome} from "../Login/CustomLogin";
import { DashboardAnalytics } from "./DashboardAnalytics";
import { DashboardNews } from "./DashboardNews";

const Dashboard = () => {
    const [roles, setRoles] = useState<IRole[]>([]);
    const {permissions} = usePermissions();
    const {identity} = useGetIdentity();

    const {id: organizationId} = JSON.parse(
        localStorage.getItem("organization") ?? "{}"
    );

    if (organizationId) redirect("/login");

    const getOrganizationId = () => {
        if (permissions.includes("admin")) return null;
        return organizationId;
    }

    useEffect(() => {
        // roles
        if (roles.length === 0)
            dataProvider
                .getList("roles")
                .then(({data: roles}) => setRoles(roles))
                .catch((err) => console.error(err));
    }, [roles.length]);

    return (
        <Container maxWidth={"lg"}>
            <Paper>
                <Stack gap={4} sx={{padding: 4}}>
                    <Welcome userName={identity?.first_name}/>
                    <DashboardAnalytics />
                    <DashboardNews />
                </Stack>
            </Paper>
        </Container>
    );
};

export default Dashboard;
