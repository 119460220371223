import { Button, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { useTranslate } from "react-admin";
import { DashboardNewsCard } from "../Layout/DashboardNewsCard";
import { useEffect, useState } from "react";
import postsService from "../../services/api/posts.service";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export const DashboardNews = () => {
  const translate = useTranslate();
  const [postsData, setPostsData] = useState([]);

  useEffect(() => {
    const fetchPostsData = async () => {
      const res = await postsService.fetchPosts();

      if (res.error) console.error(res.error);
      else setPostsData(res);
    };

    (async () => {
      try {
        fetchPostsData();
      } catch (e) {
        console.error("Erreur lors de la récupération des données", e);
      }
    })();
  }, []);

  return (
    <>
      <Typography align={"center"} variant={"h5"}>
        {translate("dashboard.news")}
      </Typography>

      <Grid container spacing={4}>
        {postsData.length === 0 &&
          <Grid item sm={12}>
            <Skeleton variant="rounded" height={200} width={'100%'} />
          </Grid>
        }
        {postsData.map((post) => (
          <DashboardNewsCard post={post} />
        ))}
      </Grid>

      <Stack alignItems={"flex-end"}>
        <Button variant="contained" component={Link} to="https://univrstudio.com/blog-univr-vr-ra/#enseignement-et-vr" target="_blank" endIcon={<ChevronRightIcon />}>
          {translate('misc.learn_more')}
        </Button>
      </Stack>
    </>
  )
};