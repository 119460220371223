import {FunctionField, TextFieldProps} from "react-admin";
import React from "react";

const BatteryField = (props: TextFieldProps) => {
    return (
        <FunctionField
            {...props}
            render={(record) => {
                if (record.battery === null) return "N/A";
                const batteryValue = record.battery;
                let color = "green";
                if (batteryValue < 20) color = "red";
                else if (batteryValue < 50) color = "orange";

                return (
                    <div style={{
                        width: '100%',
                        backgroundColor: 'rgb(172 172 172)',
                        borderRadius: '4px',
                        overflow: 'hidden',
                        position: 'relative',
                        border: '2px solid rgb(172 172 172)'
                    }}>
                        <div style={{
                            width: `${batteryValue}%`,
                            backgroundColor: color,
                            height: '100%',
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            borderRadius: '4px'
                        }}></div>
                        <div style={{
                            position: 'relative',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white',
                            fontSize: '0.8rem',
                            zIndex: 1,
                        }}>
                            {`${batteryValue}`}
                        </div>
                    </div>
                );
            }}
        />
    );
};

export default BatteryField;