import {Card, CardContent, Stack, Typography, Button, Box, Paper, Divider, Grid} from "@mui/material";
import {useEffect, useState} from "react";
import { DashboardAnalyticsCard } from "../Layout/DashboardAnalyticsCard";
import { useTranslate } from "react-admin";
import InsightsIcon from '@mui/icons-material/Insights';
import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import insightsService, { MonthlyUsageTrendsData } from "../../services/api/insights.service";
import { DashboardBarChart } from "../Layout/DashboardBarChart";
import { DashboardTopList } from "../Layout/DashboardTopList";
import licenseService from "../../services/license-service";
import { theme } from "../../global/styles";
import moment from "moment";

interface TotalTimeProps {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

interface GlobalInsightsProps {
  avg_headset_count: number;
  avg_session_time: string;
  most_used_home_version: string;
  organization_id: number;
  session_count: number;
  user_count: number;
  total_time_in_vr: TotalTimeProps;
}

interface managerLicenseProps {
  type: string;
  end_date: string;
}

export const DashboardAnalytics = () => {
  const translate = useTranslate();
  
  const { id: organizationId } = JSON.parse(
    localStorage.getItem("organization") || "{}"
  );

  const [topListData, setTopListData] = useState([]);
  const [barChartData, setBarChartData] = useState<MonthlyUsageTrendsData[]>([]);
  const [managerLicense, setManagerLicense] = useState<managerLicenseProps>({
    type: '',
    end_date: '',
  });
  const [globalInsights, setGlobalInsights] = useState<GlobalInsightsProps>({
    avg_headset_count: null,
    avg_session_time: null,
    most_used_home_version: null,
    organization_id: null,
    session_count: null,
    user_count: null,
    total_time_in_vr: {
      days: null,
      hours: null,
      minutes: null,
      seconds: null
    },
  });

  useEffect(() => {
    const fetchManagerLicense = async () => {
      const res = await licenseService.fetchCurrentManagerLicense();

      if (res.error) console.error(res.error);
      else setManagerLicense({
        type: res.type,
        end_date: res.end_date
      });
    };

    const fetchGlobalInsights = async () => {
      const res = await insightsService.fetchGlobalInsights();

      if (res.error) console.error(res.error);
      else setGlobalInsights(res);
    };

    const fetchBarChart = async () => {
      const res = await insightsService.getUsageTrends({
        organization_id: organizationId,
        view: "bar",
        get_only_total: true,
      });

      if (res.error) console.error(res.error);
      else setBarChartData(insightsService.formatUsageTrends(res));
    };

    const fetchTopList = async () => {
      const res = await insightsService.getTopExperiences({
        organization_id: organizationId,
        limit: 6,
      })

      if (res.error) console.error(res.error);
      else setTopListData(res);
    };

    (async () => {
      try {
        fetchManagerLicense();
        fetchGlobalInsights();
        fetchBarChart();
        fetchTopList();
      } catch (e) {
        console.error("Erreur lors de la récupération des données", e);
      }
    })();
  }, []);

  return (
    <>
      {managerLicense.type && (managerLicense.type === 'trial' || managerLicense.type === 'paid') ? (
        <Typography align="center">
          Votre {managerLicense.type == 'trial' ? 'essai' : 'service'} est actif jusqu'au
          <span style={{color: Math.abs(moment(managerLicense.end_date).diff(moment(), 'weeks')) > 2 ? theme.palette.primary.main : theme.palette.error.main}}> {moment(managerLicense.end_date).format('LL')}</span>.
        </Typography>
      ) : (
        <></>
      )}

      <Stack gap={4} direction="row" alignItems={"stretch"} flexWrap={"wrap"}>
        <DashboardAnalyticsCard data={globalInsights.user_count} title={`#${translate('resources.users')}`} endpoint={`/organizations/${organizationId}/users`} />
        <DashboardAnalyticsCard data={globalInsights.total_time_in_vr} title={translate('dashboard.sessions_duration')} endpoint={false} />
        <DashboardAnalyticsCard data={globalInsights.session_count} title={`#${translate('dashboard.utilizations')}`} endpoint={false} />

        <Box height="auto" flex="1 1 0px">
          <Card sx={{height: "100%"}}>
            <CardContent sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-start",
              height: "100%",
              "&: last-child": {
                paddingBottom: 2,
              }
            }}>
              <Box>
                <InsightsIcon />
                <Typography variant="body2" mt={1}>{translate('dashboard.analytics')}</Typography>
              </Box>
              <Button
                sx={{marginTop: 1}}
                color={"primary"}
                size="small"
                variant="contained"
                component={Link}
                to={"https://analytics.univr-solutions.com"}
                target="_blank"
                endIcon={<ChevronRightIcon />}
              >
                {translate('dashboard.external_link')}
              </Button>
            </CardContent>
          </Card>
        </Box>
      </Stack>

      <Grid container spacing={4}>
        <Grid item sm={12} lg={8}>
          <DashboardBarChart data={barChartData} />
        </Grid>
        <Grid item sm={12} lg={4}>
          <DashboardTopList data={topListData} maxValue={globalInsights.session_count} />
        </Grid>
      </Grid>

      <Stack alignItems={"flex-end"}>
        <Button variant="contained" component={Link} to="/experiences" endIcon={<ChevronRightIcon />}>
          {translate('resources.my_experiences')}
        </Button>
      </Stack>
    </>
  )
};