import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Checkbox,
  DialogActions,
  Button,
  Stack,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useNotify, useRefresh, useTranslate } from "react-admin";

interface CGUDialogProps {
  userId: number;
  cguAlreadyAccepted: boolean;
  forceAccept?: boolean;
}

const CGUDialog: React.FC<CGUDialogProps> = ({
  userId,
  cguAlreadyAccepted,
  forceAccept = false,
}) => {
  const [open, setOpen] = React.useState(forceAccept);
  const [isChecked, setIsChecked] = React.useState(false);
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsChecked(false);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let date = null;
    let error = null;

    try {
      await axios.post(`/users/${userId}/cgu/accept`).then((response) => { date = response.data.date });
    } catch (err) {
      error = err.response?.status || 500;
      if (error == 403) {
        date = err.response.data.date;
      }
    }

    handleAcceptCGU(date, error);
    
    if (!error || error == 403) {
      handleClose();
    }
  };

  const handleAcceptCGU = async (date: string, error: number) => {
    const user = localStorage.getItem("me");
    if (user && date) {
      localStorage.setItem("me", JSON.stringify({ ...JSON.parse(user), cgu_accepted_at: date }));
      error == 403 ?
        notify("profile.duplicate_error", { type: "error" })
      :
        notify(translate("profile.cgu_success"), { type: "success" });
    } else {
      notify("ra.notification.http_error", { type: "error" });
    }
    refresh();
  };

  return (
    <React.Fragment>
      {!forceAccept && (
        <Button size='small' variant='contained' onClick={handleClickOpen}>
          {translate("profile.view_cgu")}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={forceAccept ? null : handleClose}
        disableEscapeKeyDown={forceAccept}
        PaperProps={{
          component: "form",
          onSubmit: handleSubmit,
        }}
        maxWidth='lg'
        fullWidth
      >
        <DialogTitle>{translate("profile.cgu_title")}</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <DialogContentText>
              <iframe
                src='https://univrdev.com/cgu/cgu_fr.pdf'
                width='100%'
                height='600px'
                style={{
                  borderRadius: 16,
                  border: 'none',
                  boxShadow:
                    "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
                }}
              />
            </DialogContentText>
            {!cguAlreadyAccepted && (
              <FormControlLabel
                control={
                  <Checkbox
                    name='accept_cgu'
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    autoFocus
                  />
                }
                label={translate("profile.cgu_accept_label")}
                autoFocus
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          {!forceAccept && (
            <Button onClick={handleClose}>{translate("misc.cancel")}</Button>
          )}
          {!cguAlreadyAccepted && (
            <Button type='submit' variant='contained' disabled={!isChecked}>
              {translate("misc.accept")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default CGUDialog;
