import { RichTextInput } from "ra-input-rich-text";
import {
  ImageField,
  ImageInput,
  required,
  TextInput,
  useTranslate,
} from "react-admin";
import { managerUseStyles } from "../StepperManager";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { FormHelperText } from "@mui/material";
import CustomInput from "../../../Layout/Inputs/CustomInput";

interface ManagerGeneralFormTabProps {}

const ImageHelpText: React.FC = () => {
  const translate = useTranslate();
  const text = translate("misc.upload_image_helper");
  const sentences = text.split(". ");

  return (
    <div style={{ color: "GrayText" }}>
      {sentences.map((sentence, index) => (
        <FormHelperText key={index}>{sentence}.</FormHelperText>
      ))}
    </div>
  );
};

const ManagerGeneralFormTab: React.FC<ManagerGeneralFormTabProps> = () => {
  const classes = managerUseStyles();
  const translate = useTranslate();

  return (
    <div className={classes.container}>
      <CustomInput
        inputComponent={TextInput}
        inputComponentProps={{
          source: "name",
          validate: required(),
          isRequired: true,
        }}
        label={translate("experiences.experience_title")}
        helperText={translate("misc.seventy_characters_max")}
      />

      <CustomInput
        inputComponent={RichTextInput}
        inputComponentProps={{
          source: "description",
          validate: required(),
          isRequired: true,
        }}
        label={translate("misc.description")}
        fullWidth
      />

      <CustomInput
        inputComponent={ImageInput}
        inputComponentProps={{
          accept: "image/*",
          source: "images",
          label: translate("misc.image"),
          placeholder: (
            <UploadFileIcon
              fontSize='large'
              color='action'
              style={{ marginBlock: "2em" }}
            />
          ),
          multiple: false,
          // validate: required(),
          // isRequired: true,
          children: (
            <>
              <ImageField source='public_display_link' title='id' />
              <ImageField source='src' title='id' />
            </>
          ),
        }}
        fullWidth
        helperText={<ImageHelpText />}
        required={false}
      />
    </div>
  );
};

export default ManagerGeneralFormTab;
