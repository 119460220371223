import { Box, Divider, LinearProgress, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { theme } from "../../global/styles";
import { makeStyles } from "@mui/styles";
import { useTranslate } from "react-admin";

const useStyle = makeStyles({
  row: {
    backgroundColor: 'transparent',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    "&:nth-child(even)": {
      backgroundColor: 'transparent',
    },
    "&:last-of-type": {
      borderBottom: 'none',
    }
  },
  head: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  progress: {
    backgroundColor: theme.palette.grey[200],
    width: '100px',
    borderRadius: '3rem',
    height: '8px',
  }
});

export const DashboardTopList = ({ data, maxValue }) => {
  const classes = useStyle();
  const translate = useTranslate();
  const titleMaxLength = 40;

  return (
    <Paper sx={{height: '100%'}}>
      {data && maxValue != null ? (
        <>
          <Typography borderRadius="18px 18px 0px 0px" color='white' fontWeight={600} bgcolor={theme.palette.primary.main} p={2}>{translate('dashboard.most_used_experiences')}</Typography>

          <Divider />

          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.head}>
                  <Typography variant="body2">{translate('resources.experiences')}</Typography>
                </TableCell>
                <TableCell className={classes.head}>
                  <Typography variant="body2">{translate('dashboard.utilizations')}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            
            <TableBody>
              {data.map((row: any, index: number) => (
                <TableRow key={`${row.name}-${index}`} className={classes.row}>
                  <TableCell>
                    <Typography variant="body2">{row.name.length > titleMaxLength ? <>{row.name.slice(0, titleMaxLength - 1)}&hellip;</> : row.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" alignItems="center" gap={2} justifyContent="space-between">
                      <Typography variant="body2">{row.count}</Typography>

                      <LinearProgress variant="determinate" value={(row.count)*100/maxValue} color="primary" className={classes.progress}/>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {data.length === 0 &&
            <Box padding={2}>
              <Typography fontWeight={400} color={theme.palette.grey[600]} fontSize={14}>{translate('dashboard.empty_top_list')}</Typography>
            </Box>
          }
        </>
      ) :
      (
        <Skeleton variant="rounded" height="100%" />
      )}
    </Paper>
  )
}