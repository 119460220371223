import React from "react";
import { FunctionField, useTranslate } from "react-admin";
import PendingIcon from "@mui/icons-material/Pending";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { CircularProgress, Stack, Typography } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import TextWithIcon from "../Typographies/TextWithIcon";

interface StatusFieldProps {
  source: string;
  label: string;
}

const StatusField: React.FC<StatusFieldProps> = ({ source, label }) => {
  const translate = useTranslate();
  return (
    <FunctionField
      source={source}
      render={(record) => {
        switch (record.status) {
          case "pending":
            return (
              <TextWithIcon
                text={translate("devices.actions.status.pending")}
                icon={PendingIcon}
                color='orange'
              />
            );
          case "done":
            return (
              <TextWithIcon
                text={translate("devices.actions.status.done")}
                icon={CheckCircleIcon}
                color='green'
              />
            );
          case "error":
            return (
              <TextWithIcon
                text={translate("devices.actions.status.error")}
                icon={ErrorIcon}
                color='red'
              />
            );
          case "progress":
            return (
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <CircularProgress color={"primary"} size={18} />
                <Typography variant={"body2"} color={"primary"}>
                    {translate("devices.actions.status.progress")}
                </Typography>
              </Stack>
            );
          default:
            return (
              <TextWithIcon
                text={translate("devices.actions.status.undefined")}
                icon={QuestionMarkIcon}
                color='gray'
              />
            );
        }
      }}
    />
  );
};

export default StatusField;
