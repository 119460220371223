import {Paper, Stack, Typography} from "@mui/material";

export default function ListLayout({title, children}) {
    return (
        <Paper>
            <Stack gap={2} py={4} px={6}>
                <Typography textAlign={'center'} variant={'h5'}>{title}</Typography>
                {children}
            </Stack>
        </Paper>
    );
}