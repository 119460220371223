import {Edit} from "react-admin";
import ExperienceForm from "./Form/ExperienceForm";
import useExperienceTransform from "../../hooks/useExperienceTransform";
import CustomLinearProgress from "../Layout/Progresses/CustomProgress";

const ExperienceEdit = () => {
    const {transform, progress} = useExperienceTransform();

    return (
        <Edit transform={transform} redirect={"/experiences"}>
            <ExperienceForm isUploading={!!progress}/>
            {progress && <CustomLinearProgress value={progress}/>}
        </Edit>
    );
};

export default ExperienceEdit;
