import { Create } from "react-admin";
import licenseService from "../../services/license-service";
import LicenseForm from "./LicenseForm";
import { useParams } from "react-router-dom";

interface LicenseCreateProps {
  parentResource?: "organizations" | "experiences";
}

const LicenseCreate: React.FC<LicenseCreateProps> = ({ parentResource = "organizations" }) => {
  const { id: parentResourceId } = useParams();

  return (
    <Create
      transform={licenseService.transformLicense}
      resource='licenses'
      redirect={`/${parentResource}/${parentResourceId}/licenses`}
    >
      <LicenseForm mode='create' parentResource={parentResource} />
    </Create>
  );
};

export default LicenseCreate;
