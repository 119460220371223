import {
  FileField,
  FileInput,
  regex,
  required,
  SelectInput,
  TextInput,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { managerUseStyles } from "../StepperManager";
import { FormHelperText, Stack } from "@mui/material";
import { RichTextInput } from "ra-input-rich-text";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import React from "react";
import CustomInput from "../../../Layout/Inputs/CustomInput";
import { useWatch } from "react-hook-form";
import TagInput from "../../../Layout/Inputs/TagInput";

interface ManagerTechnicalFormTabProps {
  tags: any[];
}

const BuildHelpText: React.FC = () => {
  const type = useWatch({ name: "type" });
  const translate = useTranslate();
  return (
    <div style={{ color: "GrayText" }}>
      <FormHelperText>{translate("misc.archive_exe_video")}</FormHelperText>
      {type === "video" && (
        <FormHelperText>
          {translate("misc.video_formats_supported")}
        </FormHelperText>
      )}
      <FormHelperText
        dangerouslySetInnerHTML={{ __html: translate("misc.build_helper") }}
      />
    </div>
  );
};

const ManagerTechnicalFormTab: React.FC<ManagerTechnicalFormTabProps> = ({
  tags,
}) => {
  const classes = managerUseStyles();
  const record = useRecordContext();
  const creating = !record;
  const type = useWatch({ name: "type" });

  const exeRegex = /.*\.(exe|apk|msi)$/;
  const notExeRegex = /^(?!.*\.(exe|apk)$).*$/;

  const validateExeName = [required(), regex(exeRegex)];
  const translate = useTranslate();

  return (
    <Stack className={classes.container} gap={2}>
      <CustomInput
        inputComponent={SelectInput}
        inputComponentProps={{
          source: "type",
          validate: required(),
          choices: [
            { id: "build", name: translate("experiences.types.build") },
            { id: "video", name: translate("experiences.types.video") },
            { id: "browser", name: translate("experiences.types.browser") },
            { id: "oculus", name: translate("experiences.types.oculus") },
            { id: "package", name: translate("experiences.types.package") },
            { id: "installer", name: translate("experiences.types.installer") },
          ],
        }}
        label={translate("experiences.types.index")}
      />

      {(type === "build" || type === "video" || type === "installer") && (
        <>
          <CustomInput
            inputComponent={FileInput}
            inputComponentProps={{
              accept: ".zip",
              source: "build",
              label: translate("misc.zip_file"),
              placeholder: (
                <UploadFileIcon
                  fontSize='large'
                  color='action'
                  style={{ marginBlock: "2em" }}
                />
              ),
              multiple: false,
              children: (
                <>
                  <FileField
                    source='public_download_link'
                    title='filename'
                  />
                  <FileField
                    source='src'
                    title='title'
                  />
                </>
              ),
            }}
            label={translate("misc.zip_file")}
            helperText={<BuildHelpText />}
            fullWidth
          />
        </>
      )}

      {type === "browser" && (
        <CustomInput
          inputComponent={TextInput}
          inputComponentProps={{
            source: "url",
            validate: required(),
          }}
          label={translate("misc.url")}
        />
      )}

      {(type === "package" || type === "installer") && (
        <CustomInput
          inputComponent={TextInput}
          inputComponentProps={{
            source: "package_name",
            validate: required(),
          }}
          label={
            type === "installer"
              ? translate("experiences.installed_software_name")
              : translate("experiences.package_name")
          }
          helperText={
            type === "installer"
              ? null
              : translate("experiences.meta_quest_store_package_name")
          }
        />
      )}

      {(type === "build" || type === "oculus" || type === "installer") && (
        <CustomInput
          inputComponent={TextInput}
          inputComponentProps={{
            source: "executable_name",
            validate: validateExeName,
          }}
          label={
            type === "installer"
              ? translate("experiences.installer_name")
              : translate("experiences.exectuable_name")
          }
          helperText={
            type === "installer"
              ? null
              : translate("experiences.installer_help_text")
          }
        />
      )}

      <TagInput
        source={"editeur_ids"}
        type={"experienceEditeur"}
        label={translate("tags.editors.index")}
        createText={translate("tags.editors.create")}
        noOptionsText={translate("tags.editors.not_found")}
      />

      <TagInput
        source={"compatible_headset_ids"}
        type={"experienceHeadset"}
        label={translate("tags.headsets.index")}
        createText={translate("tags.headsets.create")}
        noOptionsText={translate("tags.headsets.not_found")}
      />

      <TagInput
        source={"label_ids"}
        type={"experienceTag"}
        label={translate("tags.tags.index")}
        createText={translate("tags.tags.create")}
        noOptionsText={translate("tags.tags.not_found")}
        required={false}
      />

      {!creating ? (
        <CustomInput
          inputComponent={RichTextInput}
          inputComponentProps={{
            source: "version_description",
            defaultValue: translate("experiences.version.initial"),
            validate: required(),
          }}
          label={translate("experiences.version.description")}
          fullWidth
        />
      ) : (
        <TextInput
          name={"version_description"}
          source={"version_description"}
          style={{ display: "none" }}
          defaultValue={translate("experiences.version.initial")}
          validate={required()}
        />
      )}
    </Stack>
  );
};

export default ManagerTechnicalFormTab;
