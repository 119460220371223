import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { useRecordContext, useTranslate } from "react-admin";

const OrganizationListButton = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  return (
    <Button
      component={Link}
      to={`/experience-groups/${record.id}/organizations`}
      variant='text'
      color='primary'
      onClick={e => e.stopPropagation()}
    >
      {translate("resources.organizations")}
    </Button>
  );
};

export default OrganizationListButton;
