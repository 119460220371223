import {
  Filter,
  SelectInput,
  TextInput,
  useListContext,
  useTranslate,
} from "react-admin";
import React from "react";
import { Box } from "@mui/material";

interface DefaultFilterProps {
  hasStatusFilter: boolean;
  statusFilterLabel?: string;
  statusFilterName?: string;
  hasSearchField?: boolean;
}

const DefaultFilter: React.FC<DefaultFilterProps & any> = ({
  hasStatusFilter,
  statusFilterLabel,
  statusFilterName,
  ...props
}) => {
  const translate = useTranslate();
  const { selectedIds } = useListContext();
  const shouldMoveFilters = selectedIds.length > 0;

  const choices = [
    { id: "active", name: translate("status.active") },
    { id: "expired", name: translate("status.expired") },
  ];

  return (
    <Box sx={{ mb: shouldMoveFilters ? 4 : 0 }}>
      <Filter {...props}>
        <TextInput
          label={translate("misc.search")}
          variant='outlined'
          source='search'
          alwaysOn
        />
        <SelectInput
          name={statusFilterName || "status"}
          source={"status"}
          variant={"outlined"}
          choices={choices}
          alwaysOn={hasStatusFilter}
          label={statusFilterLabel || translate("status.index")}
          emptyText={translate("status.undefined")}
          sx={{ pb: "4px" }}
        />
      </Filter>
    </Box>
  );
};

DefaultFilter.defaultProps = {
  hasStatusFilter: false,
};

export default DefaultFilter;
