import {
  AutocompleteArrayInput,
  AutocompleteInput,
  DateInput,
  FormDataConsumer,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  usePermissions,
  useRecordContext,
  useRedirect,
  useTranslate,
} from "react-admin";
import { hasAccess } from "../../global/permissions";
import {
  Container,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
} from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import CustomSimpleToolbar from "../Layout/CustomSimpleToolbar";
import SkeletonForm from "../Loading/SkeletonForm";

interface LicenseFormProps {
  mode?: "create" | "edit";
  parentResource?: "organizations" | "experiences";
  redirectUrl?: string;
}

const LicenseForm: React.FC<LicenseFormProps> = ({
  mode = "create",
  parentResource = "organizations",
  redirectUrl,
}) => {
  const redirect = useRedirect();
  const { id: parentResourceId } = useParams();
  const { id: userOrgaId } = JSON.parse(localStorage.getItem("organization")); // User organization id
  const { permissions } = usePermissions();
  const record = useRecordContext();
  const [displaySelect, setDisplaySelect] = useState(
    record?.user_ids?.length > 0 ?? false
  );
  const translate = useTranslate();

  const NameField = () => {
    const record = useRecordContext();
    return <span>{record.name}</span>;
  };

  /**
   * Validate data.
   * @param values
   */
  const validateForm = (values) => {
    const errors = {};

    if (!values.started_at) {
      errors["started_at"] = translate("ra.validation.required");
    }

    if (!values.type) {
      errors["type"] = translate("ra.validation.required");
    }

    return errors;
  };

  if (!permissions || !userOrgaId)
    return (
      <div>
        <SkeletonForm />
      </div>
    );

  if (!hasAccess({ name: "licenses", crudType: "edit" }, permissions))
    redirect("/");

  return (
    <Container maxWidth={"sm"}>
      <SimpleForm
        validate={validateForm}
        toolbar={<CustomSimpleToolbar deleteRedirect={redirectUrl} />}
      >
        {/* Hidden field */}
        <NumberInput
          source={
            parentResource === "experiences"
              ? "experience_id"
              : parentResource === "organizations"
              ? "organization_id"
              : null
          }
          defaultValue={parentResourceId}
          required
          sx={{ display: "none" }}
        />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.type !== "same_as_service_license" && (
              <DateInput
                source='started_at'
                label={translate("misc.start_date")}
                defaultValue={Date.now()}
                required
              />
            )
          }
        </FormDataConsumer>

        <SelectInput
          source='type'
          defaultValue='6 months'
          label={translate("dates.duration")}
          choices={[
            { id: "6 months", name: translate("dates.6_months") },
            { id: "1 year", name: translate("dates.1_year") },
            { id: "unlimited", name: translate("dates.unlimited") },
            { id: "custom", name: translate("dates.custom") },
            {
              id: "same_as_service_license",
              name: translate("dates.same_as_service_license"),
            },
          ]}
          required
        />

        {/* add organization reference input */}
        {parentResource === "experiences" && (
          <ReferenceInput
            source='organization_id'
            reference='organizations'
            perPage={9999}
            sort={{ field: "name", order: "ASC" }}
          >
            <AutocompleteInput
              label={translate("profile.organization")}
              optionText={<NameField />}
              inputText={(option) => option.name}
              matchSuggestion={(filterValue, suggestion) =>
                suggestion.name
                  .toLowerCase()
                  .includes(filterValue.toLowerCase())
              }
              variant='outlined'
              sx={{ width: "100%", maxWidth: "24em" }}
            />
          </ReferenceInput>
        )}

        {parentResource === "organizations" && (
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.type === "custom" && (
                <DateInput
                  {...rest}
                  source='ended_at'
                  label={translate("misc.end_date")}
                  required
                />
              )
            }
          </FormDataConsumer>
        )}

        {parentResource === "organizations" && (
          <FormDataConsumer>
            {({ formData, scopedFormData, ...rest }) => (
              <Stack maxWidth='24em' width='100%'>
                {!formData.tag_id && (
                  <ReferenceInput
                    label={translate("experiences.singular_name")}
                    source='experience_id'
                    reference='experiences'
                    perPage={9999}
                    filter={
                      !permissions.includes("admin")
                        ? { organization_id: userOrgaId }
                        : null
                    }
                    sort={{ field: "name", order: "ASC" }}
                  >
                    <AutocompleteInput
                      optionText={<NameField />}
                      inputText={(option) => option.name}
                      matchSuggestion={(filterValue, suggestion) =>
                        suggestion.name
                          .toLowerCase()
                          .includes(filterValue.toLowerCase())
                      }
                      variant='outlined'
                      sx={{ width: "100%", maxWidth: "24em" }}
                    />
                  </ReferenceInput>
                )}

                {!formData.tag_id && !formData.experience_id && (
                  <Divider flexItem>OU</Divider>
                )}

                {!formData.experience_id && (
                  <ReferenceInput
                    source='experience_group_id'
                    reference='experience-groups'
                    perPage={9999}
                  >
                    <AutocompleteInput
                      label={translate("experience-groups.singular_name")}
                      inputText={(option) => option.name}
                      matchSuggestion={(filterValue, suggestion) =>
                        suggestion.name
                          .toLowerCase()
                          .includes(filterValue.toLowerCase())
                      }
                      variant='outlined'
                      sx={{ width: "100%", maxWidth: "24em" }}
                    />
                  </ReferenceInput>
                )}
              </Stack>
            )}
          </FormDataConsumer>
        )}

        <FormControlLabel
          control={
            <Switch
              onChange={(e) => setDisplaySelect(e.target.checked)}
              value={displaySelect}
              defaultChecked={displaySelect}
              color='primary'
            />
          }
          label={translate("misc.specific_users")}
        />

        {displaySelect && (
          <FormDataConsumer>
            {({ formData, scopedFormData, ...rest }) => (
              <ReferenceArrayInput
                source='user_ids'
                reference='users'
                perPage={9999}
                filter={{ organization_id: formData.organization_id }}
                sort={{ field: "first_name", order: "ASC" }}
              >
                <AutocompleteArrayInput
                  optionText={(user) =>
                    user ? `${user?.first_name} ${user?.last_name}` : "-"
                  }
                  sx={{ width: "100%", maxWidth: "24em" }}
                  label={translate("resources.users")}
                  disableCloseOnSelect
                  blurOnSelect={false}
                />
              </ReferenceArrayInput>
            )}
          </FormDataConsumer>
        )}
      </SimpleForm>
    </Container>
  );
};

export default LicenseForm;
