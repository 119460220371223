import { Card, CardContent, Typography, Button, Skeleton, Box, CardHeader, CardActions } from "@mui/material"
import { theme } from "../../global/styles"
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { useTranslate } from "react-admin";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const useStyles = makeStyles({
  container: {
    flex: "1 1 0px",
    height: "auto",
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  button: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexGrow: 1,
    paddingBottom: 16,
    paddingRight: 16,
  }
});

export const DashboardAnalyticsCard = ({ data, title, endpoint }) => {
  const classes = useStyles();
  const translate = useTranslate();

  const formatTotalTime = ({ hours, minutes, seconds }) => {
    const totalDays = Math.floor(hours / 24);
    const remainingHours = hours % 24;

    return totalDays > 0
    ? `${totalDays} jours ${remainingHours} heures et ${minutes} minutes`
    : hours === 0
    ? (minutes === 0 ? `${seconds} secondes` : `${minutes} minutes et ${seconds} secondes`)
    : (minutes === 0 ? `${hours} heures et ${seconds} secondes` : `${hours} heures et ${minutes} minutes`);
  }

  return (
    <Box className={classes.container}>
      {data != null && (typeof data !== 'object' || !Object.values(data).some(value => value === null)) ? (
        <Card className={classes.card}>
          <CardHeader
            title={title}
            titleTypographyProps={{
              variant: "subtitle2",
              color: theme.palette.grey[600],
            }}
          />
          <CardContent sx={{ textAlign: "center", mt: -1 }}>
            <Typography fontWeight={600} color='primary.main' variant="h6">{data = typeof data === 'object' ? formatTotalTime(data) : data}</Typography>
          </CardContent>
          {endpoint && (
            <CardActions className={classes.button}>
              <Button
                size='small'
                variant='contained'
                href={endpoint}
                // endIcon={<ChevronRightIcon />}
              >
                {translate("misc.learn_more")}
              </Button>
            </CardActions>
          )}
        </Card>
      ) :
      (
        <Skeleton variant="rounded" height="100%" />
      )}
    </Box>
  )
}