import React, {useState} from "react";
import {useListContext, useRefresh, useTranslate, useUnselectAll} from "react-admin";
import {deleteManyDevices} from "../../../services/api/devices.service";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography} from "@mui/material";

const DeleteWithConfirmDialog: React.FC = () => {
    const [open, setOpen] = useState(false);
    const {selectedIds} = useListContext();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll('devices');
    const translate = useTranslate();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        try {
            await deleteManyDevices(selectedIds);
            unselectAll();
            refresh();
            handleClose();
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <React.Fragment>
            <Button variant="outlined" color="error" onClick={handleClickOpen}>
                {translate("misc.delete")}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="confirm-dialog-title"
            >
                <DialogTitle id="confirm-dialog-title">{translate("devices.actions.delete_dialog.title")}</DialogTitle>
                <DialogContent>
                    <Stack justifyContent={'center'} alignItems={'center'} gap={1} my={4}>
                        <Typography variant="body1">
                            {translate("devices.actions.delete_dialog.confirmation")}
                        </Typography>
                        <Typography variant="body2" color={"gray"}>
                            {translate("devices.actions.delete_dialog.information")}
                        </Typography>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        {translate("misc.cancel")}
                    </Button>
                    <Button onClick={handleDelete} color="error" variant={"contained"}>
                        {translate("misc.delete")}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default DeleteWithConfirmDialog;