import { Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

// --------------------- API info --------------------- //
export const APIURL = process.env.REACT_APP_API_URL;
export const HEADERS = new Headers({
  "Content-Type": "application/json",
  locale: "fr",
});

// STYLES
export const useGlobalStyles = makeStyles((theme) => ({
  form: {
    padding: "0 2em 1em 1em",
  },
  tab: {
    padding: "0 2em 1em 1em",
  },
}));

export const flattenObject = function (ob) {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] == "object") {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

export function stringifyQuery(query) {
  let stringifiedQuery = "";

  const keys = Object.keys(query);
  keys.forEach((key) => {
    stringifiedQuery += `${key}=${query[key]}&`;
  });

  return stringifiedQuery.slice(0, -1);
}

export const formatDate = (dateStr: string): string => {
  return new Date(dateStr).toLocaleString("fr-FR");
};
