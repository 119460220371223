import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  Loading,
  ReferenceArrayField,
  ReferenceField,
  TextField,
  TopToolbar,
  usePermissions,
  useRecordContext,
  useRedirect,
  useTranslate,
} from "react-admin";
import { hasAccess } from "../../global/permissions";
import licenseService from "../../services/license-service";
import { Link, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CustomCreateButton from "../Layout/Buttons/CustomCreateButton";
import React from "react";
import DefaultFilter from "../Layout/Filters/DefaultFilter";
import ListLayout from "../Layout/ListLayout";

interface EditLicenseButtonProps extends LicenseListProps {}

const EditLicenseButton: React.FC<EditLicenseButtonProps> = ({
  parentResource,
}) => {
  const license = useRecordContext();
  const { id: parentResourceId } = useParams();
  const translate = useTranslate();

  return (
    <Button
      variant='contained'
      component={Link}
      size='small'
      to={`/${parentResource}/${parentResourceId}/licenses/${license?.id}`}
      startIcon={<EditIcon />}
    >
      {translate("misc.edit")}
    </Button>
  );
};

interface LicenseListActionsProps extends LicenseListProps {}

const LicenseListActions: React.FC<LicenseListActionsProps> = ({
  parentResource,
}) => {
  const { id: organization_id } = useParams();
  const translate = useTranslate();
  return (
    <TopToolbar
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        gap: "2rem",
      }}
    >
      {/* {parentResource === "organizations" && (
        <Button
          variant='text'
          size='small'
          component={Link}
          to={`/organizations/${organization_id}/users`}
          color='secondary'
          startIcon={<ChevronRightIcon />}
        >
          {translate("resources.users")}
        </Button>
      )} */}
      <CustomCreateButton
        to={`/${parentResource}/${organization_id}/licenses/create`}
        label={translate("licenses.create")}
      />
    </TopToolbar>
  );
};

interface ExperienceFieldProps {
  setRecord: (record: any) => void;
}

const ExperienceField: React.FC<ExperienceFieldProps> = ({ setRecord }) => {
  const record = useRecordContext();

  React.useEffect(() => {
    setRecord(record);
  }, [record]);

  // return record.experience_group_id ? (
  //   <ReferenceField
  //     label="Experience"
  //     source="experience_group_id"
  //     reference="experience_groups"
  //   >
  //     <TextField source="name" />
  //   </ReferenceField>
  // ) : (
  //   <ReferenceField
  //     label="Experience"
  //     source="experience_id"
  //     reference="experiences"
  //   >
  //     <TextField source="name" />
  //   </ReferenceField>
  // );
  return <TextField source='name' />;
};

interface LicenseListProps {
  parentResource?: "organizations" | "experiences" | "experience-groups";
}

const LicenseList: React.FC<LicenseListProps> = ({
  parentResource = "organizations",
}) => {
  const organization = JSON.parse(localStorage.getItem("organization"));
  const redirect = useRedirect();
  const { permissions } = usePermissions();
  const { id: parentResourceId } = useParams();
  const [record, setRecord] = React.useState(null);
  const translate = useTranslate();

  if (!organization || !permissions) return <Loading />;

  if (!hasAccess({ name: "licenses", crudType: "list" }, permissions))
    redirect("/");

  const filter =
    parentResource === "organizations"
      ? { organization_id: parentResourceId }
      : parentResource === "experiences"
      ? { experience_id: parentResourceId }
      : {};

  return (
    <ListLayout title={translate("resources.licenses")}>
      <List
        resource='licenses'
        filter={filter}
        filters={<DefaultFilter />}
        sort={{ field: "updated_at", order: "DESC" }}
        actions={<LicenseListActions parentResource={parentResource} />}
        emptyWhileLoading
        empty={false}
      >
        <Datagrid bulkActionButtons={false}>
          <DateField source='started_at' label={translate("misc.start_date")} />
          <FunctionField
            label={translate("misc.end_date")}
            render={licenseService.renderEndDate}
            sortBy='ended_at'
          />
          <ReferenceField
            label={translate("experience-groups.name")}
            source='experience_group_id'
            reference='experience-groups'
          >
            <ExperienceField setRecord={setRecord} />
          </ReferenceField>
          <ReferenceField
            label={translate("resources.experiences")}
            source='experience_id'
            reference='experiences'
          >
            <ExperienceField setRecord={setRecord} />
          </ReferenceField>
          {parentResource === "experiences" && (
            <ReferenceField
              label={translate("profile.organization")}
              source='organization_id'
              reference='organizations'
            >
              <TextField source='name' />
            </ReferenceField>
          )}
          <ReferenceArrayField
            label={translate("resources.users")}
            reference='users'
            source='user_ids'
            fullWidth
          ></ReferenceArrayField>
          {hasAccess({ name: "licenses", crudType: "edit" }, permissions) && (
            <EditLicenseButton parentResource={parentResource} />
          )}
        </Datagrid>
      </List>
    </ListLayout>
  );
};

export default LicenseList;
