import React from "react";
import {
  Container,
  FormLabel,
  Stack,
} from "@mui/material";
import {
  AutocompleteArrayInput,
  BooleanInput,
  Loading,
  ReferenceArrayInput,
  required,
  useGetIdentity,
  useGetOne,
  useTranslate,
} from "react-admin";
import FormControl from "@mui/material/FormControl";
import CustomInput from "../../../Layout/Inputs/CustomInput";
import { NameField } from "../ExperienceForm";

const ShareOptions = () => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const organizationId = identity?.organization?.id;
  const {
    data: organization,
    isPending,
    error,
    refetch,
  } = useGetOne("organizations", { id: organizationId });
  const showUnivrsityOption = organization?.category_ids?.includes(107);

  if (isPending || error) return <Loading />;

  return (
    <FormControl component='fieldset' variant='standard'>
      <FormLabel component='legend'>
        {translate("misc.share_options")}
      </FormLabel>
      <Stack gap={2}>
        {showUnivrsityOption && (
          <Stack
            gap={4}
            direction={"row"}
            flexWrap={"wrap"}
            alignItems={"center"}
          >
            <BooleanInput
              label='UniVRsity'
              source='included_to_univrsity'
              sx={{
                margin: 0,
              }}
              helperText={false}
            />
          </Stack>
        )}
        <CustomInput
          inputComponent={ReferenceArrayInput}
          inputComponentProps={{
            source: "licensed_organization_ids",
            reference: "organizations",
            optionText: <NameField />,
            optionValue: "name",
            validate: required(),
            label: false,
            perPage: 1000,
            queryOptions: { meta: { useCategories: true } },
            children: (
              <AutocompleteArrayInput
                label={false}
                sx={{ minWidth: "22em" }}
                multiple
                inputText={(option) => option.name}
                matchSuggestion={(filterValue, suggestion) =>
                  suggestion.name
                    .toLowerCase()
                    .includes(filterValue.toLowerCase())
                }
                noOptionsText={translate("organizations.not_found")}
                disableCloseOnSelect
                blurOnSelect={false}
              />
            ),
          }}
          fullWidth
          label={translate("organizations.list")}
          helperText={translate("organizations.list_helper")}
          required={false}
        />
      </Stack>
    </FormControl>
  );
};

interface ManagerShareFormTabProps {}

const ManagerShareFormTab: React.FC<ManagerShareFormTabProps> = () => {
  return (
    <Container maxWidth={"md"}>
      <Stack gap={4} mt={6} mb={4}>
        <ShareOptions />
      </Stack>
    </Container>
  );
};

export default ManagerShareFormTab;
