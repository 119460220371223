import React, {useState} from "react";
import {useTranslate, useUnselectAll} from "react-admin";
import MenuItem from "@mui/material/MenuItem";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import ExperienceList from "../../Experiences/ExperienceList";

function InstallAppsDialog({onSubmit}) {
    const [open, setOpen] = React.useState(false);
    const unselectAll = useUnselectAll('experiences');
    const [selectedIds, setSelectedIds] = useState([]);
    const translate = useTranslate();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        unselectAll();
    };

    const handleInstall = () => {
        onSubmit({experience_ids: selectedIds});
        handleClose();
    }

    const handleSelectedIdsChange = (ids) => {
        setSelectedIds(ids);
    }

    return (
      <React.Fragment>
        <MenuItem onClick={handleClickOpen}>
          {translate("devices.actions.install_apps_dialog.menu_item")}
        </MenuItem>
        <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth>
          <DialogTitle>
            {translate("devices.actions.install_apps_dialog.title")}
          </DialogTitle>
          <DialogContent>
            <ExperienceList
              simplifiedView
              standaloneOnly
              onSelectedIdsChange={handleSelectedIdsChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{translate("misc.cancel")}</Button>
            <Button onClick={handleInstall} autoFocus variant={"contained"}>
                {translate("misc.install")}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
}

export default InstallAppsDialog;