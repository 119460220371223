import {Edit} from "react-admin";
import licenseService from "../../services/license-service";
import LicenseForm from "./LicenseForm";
import {useParams} from "react-router-dom";

interface LicenseEditProps {
  parentResource?: "organizations" | "experiences";
}

const LicenseEdit: React.FC<LicenseEditProps> = ({ parentResource = "organizations" }) => {
  const { id: parentResourceId, licenseId } = useParams();
  const redirect = `/${parentResource}/${parentResourceId}/licenses`;

  return (
    <Edit
      transform={licenseService.transformLicense}
      resource='licenses'
      id={licenseId}
      redirect={redirect}
    >
      <LicenseForm mode='edit' parentResource={parentResource} redirectUrl={redirect}/>
    </Edit>
  );
};

export default LicenseEdit;
