import {Edit} from "react-admin";
import UserForm from "./UserForm";
import {useParams} from "react-router-dom";

const UserEdit = () => {
    const {id, userId} = useParams();
    const redirect = `/organizations/${id}/users`;

    return (
        <Edit
            resource='users'
            id={userId}
            redirect={redirect}
        >
            <UserForm redirectUrl={redirect}/>
        </Edit>
    );
};

export default UserEdit;
