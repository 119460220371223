import { Skeleton } from "@mui/material";

const SkeletonForm = () => {
  return (
    <div style={{ padding: "2em" }}>
      <Skeleton variant="text" width="30vh" height={60} />
      <Skeleton variant="text" width="70vh" height={60} />
      <Skeleton variant="text" width="50vh" height={60} />
      <Skeleton variant="text" width="70vh" height={60} />
      <Skeleton variant="text" width="50vh" height={60} />
      <Skeleton variant="text" width="70vh" height={60} />
    </div>
  );
};

export default SkeletonForm;
