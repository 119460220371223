import React from 'react';
import {Box, CircularProgress} from "@mui/material";

const CustomLoading = () => {
    return (
        <Box p={4}>
            <CircularProgress size={"100px"}/>
        </Box>
    );
};

export default CustomLoading;