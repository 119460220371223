import { useEffect, useState } from "react";
import { SelectInput, SimpleForm, TextInput, useTranslate } from "react-admin";
import dataProvider from "../../data-providers/data-provider";
import SkeletonForm from "../Loading/SkeletonForm";
import { Container } from "@mui/material";
import CustomSimpleToolbar from "../Layout/CustomSimpleToolbar";

interface TagFormProps {
  type?: string;
}

const TagForm: React.FC<TagFormProps> = ({ type }) => {
  const [types, setTypes] = useState<{ id: string; name: string }[]>([]);
  const translate = useTranslate();

  useEffect(() => {
    dataProvider.getList("tags/types").then((response) => {
      setTypes(response.data);
    });
  }, []);

  /**
   * Validate data.
   * @param values
   */
  const validateForm = (values) => {
    const errors = {};

    if (!values.name) {
      errors["name"] = translate("ra.validation.required");
    }

    if (!values.type) {
      errors["type"] = translate("ra.validation.required");
    }

    return errors;
  };

  if (types.length === 0)
    return (
      <div>
        <SkeletonForm />
      </div>
    );

  return (
    <Container maxWidth={"sm"}>
      <SimpleForm validate={validateForm} toolbar={<CustomSimpleToolbar />}>
        <TextInput source='name' label={translate("misc.name")} />
        <SelectInput
          source='type'
          choices={types}
          label={translate("misc.type")}
        />
      </SimpleForm>
    </Container>
  );
};

export default TagForm;
