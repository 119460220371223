import {
    ArrayInput,
    AutocompleteArrayInput,
    BooleanInput,
    FormDataConsumer,
    FormTab,
    NumberInput,
    required,
    SelectInput,
    SimpleFormIterator,
    TextInput,
} from "react-admin";

const ConfigFormTab = (props) => {
    // switch, radio, checkbox, number, select
    const typeOptions = [
        {id: "switch", name: "Switch"},
        {id: "radio", name: "Radio"},
        {id: "checkbox", name: "Checkbox"},
        {id: "number", name: "Nombre"},
        {id: "select", name: "Sélecteur"},
    ]

    const inputText = choice => choice.name;
    const matchSuggestion = (filter, choice) => choice.name?.toLowerCase().includes(filter?.toLowerCase());
    const getChoices = (data) => typeof data?.value === "object" ? data?.value?.map(v => ({id: v, name: v})) ?? [] : [];

    return (
        <FormTab {...props} label='Paramètres de lancement'>
            <ArrayInput source='parameters' label={false}>
                <SimpleFormIterator sx={{
                    "& .RaSimpleFormIterator-line": {
                        mb: 4,
                    }
                }}>
                    <SelectInput
                        source='type'
                        label="Type"
                        choices={typeOptions}
                        defaultValue={"switch"}
                        validate={required()}
                    />

                    <TextInput source='name' label='Nom' required/>

                    <TextInput source='display_name' label="Nom d'affichage" required/>

                    <FormDataConsumer>
                        {({scopedFormData, ...rest}) =>
                            scopedFormData.type === "switch" &&
                            <BooleanInput source={"default"} label={"Valeur par défaut"} {...rest} />
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({scopedFormData, ...rest}) =>
                            ["radio", "checkbox", "select"].includes(scopedFormData.type) &&
                            <>
                                <AutocompleteArrayInput
                                    source={'value'}
                                    choices={getChoices(scopedFormData)}
                                    onCreate={(o) => ({id: o, name: o})}
                                    inputText={inputText}
                                    matchSuggestion={matchSuggestion}
                                    {...rest}
                                />
                                {/*  default (as select) */}
                                <SelectInput
                                    source={"default"}
                                    label={"Valeur par défaut"}
                                    choices={getChoices(scopedFormData)}
                                    {...rest}
                                />
                            </>
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({scopedFormData, ...rest}) =>
                            scopedFormData.type === "number" &&
                            <>
                                <NumberInput source={"default"} label={"Valeur par défaut"} {...rest} />
                            </>
                        }
                    </FormDataConsumer>

                </SimpleFormIterator>
            </ArrayInput>
        </FormTab>
    );
};

export default ConfigFormTab;
