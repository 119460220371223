import React from "react";
import {
  Create,
  DateInput,
  Edit,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  usePermissions,
  useRedirect,
  useTranslate,
} from "react-admin";
import { Container } from "@mui/material";
import CustomSimpleToolbar from "../Layout/CustomSimpleToolbar";
import { useParams } from "react-router-dom";
import { hasAccess } from "../../global/permissions";
import moment from "moment";
import EndDateInput from "./EndDateInput";
import { useWatch } from "react-hook-form"; // Import the extracted component

const SERVICE_TYPES = [
  { id: "paid", name: "service-licenses.types.paid" },
  { id: "trial", name: "service-licenses.types.trial" },
  { id: "unlimited", name: "service-licenses.types.unlimited" },
];

const SERVICES = [
  { id: "home", name: "service-licenses.services.home" },
  { id: "analytics", name: "service-licenses.services.analytics" },
  { id: "manager", name: "service-licenses.services.manager" },
];

export const ServiceLicenseCreate: React.FC = () => {
  const { id: organizationId } = useParams();
  return (
    <Create
      resource='service-licenses'
      redirect={`/organizations/${organizationId}/service-licenses`}
    >
      <ServiceLicenseForm />
    </Create>
  );
};

export const ServiceLicenseEdit: React.FC = () => {
  const { id: organizationId, serviceLicenseId } = useParams();
  return (
    <Edit
      id={serviceLicenseId}
      resource='service-licenses'
      redirect={`/organizations/${organizationId}/service-licenses`}
    >
      <ServiceLicenseForm />
    </Edit>
  );
};

const TypeInput = () => {
  const type = useWatch<{ type: string }>({ name: "type" });
  const isTrial = type === "trial";
  const translate = useTranslate();
  return (
    <SelectInput
      source='type'
      defaultValue='paid'
      label={translate("misc.type")}
      choices={SERVICE_TYPES.map((type) => ({
        ...type,
        name: translate(type.name),
      }))}
      fullWidth
      validate={required()}
      helperText={
        isTrial ? translate("service-licenses.types.trial_helper") : undefined
      }
    />
  );
};

const ServiceLicenseForm: React.FC = () => {
  const redirect = useRedirect();
  const { id: organizationId } = useParams();
  const { permissions } = usePermissions();
  const translate = useTranslate();

  const validateForm = (values: { [x: string]: any }) => {
    const requiredFields = ["type"]; // Add more required fields as needed
    const errors: Record<string, string> = {};

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = translate("validation.required");
      }
    });

    return errors;
  };

  if (!hasAccess({ name: "service-licenses", crudType: "edit" }, permissions)) {
    redirect("/");
  }

  return (
    <Container maxWidth={"xs"}>
      <SimpleForm
        validate={validateForm}
        toolbar={
          <CustomSimpleToolbar
            deleteRedirect={`/organizations/${organizationId}/service-licenses`}
          />
        }
        defaultValues={{
          type: "paid",
          start_date: moment(),
          end_date: moment().add(1, "year").format("YYYY-MM-DD"),
          services: ["home"],
          organization_id: organizationId,
        }}
      >
        <TypeInput />

        <DateInput
          source='start_date'
          label={translate("misc.start_date")}
          required
          fullWidth
          name={"start_date"}
        />

        <EndDateInput />

        <SelectArrayInput
          source='services'
          label={translate("service-licenses.services.index")}
          choices={SERVICES.map((service) => ({
            ...service,
            name: translate(service.name),
          }))}
          fullWidth
          required
        />
      </SimpleForm>
    </Container>
  );
};

export default ServiceLicenseForm;
