import { Create } from "react-admin";
import OrganizationForm from "./OrganizationForm";
import { transformOrganizationForm } from "./OrganizationEdit";

const OrganizationCreate = (props) => {
  return (
    <Create
      transform={transformOrganizationForm}
      actions={null}
      sx={{
        "& .RaCreate-card": {
          background: "none",
          boxShadow: "none",
          border: "none",
        },
      }}
      redirect='show'
    >
      <OrganizationForm {...props} />
    </Create>
  );
};

export default OrganizationCreate;
