import {
  Loading,
  MenuItemLink,
  useGetIdentity,
  useGetOne,
  usePermissions,
  useTranslate,
} from "react-admin";
import { hasAccess } from "../../global/permissions";
import { Stack } from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";
import BusinessIcon from "@mui/icons-material/Business";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LabelIcon from "@mui/icons-material/Label";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import GroupIcon from "@mui/icons-material/Group";
import DevicesIcon from "@mui/icons-material/Devices";
import { CustomMenuItemLink } from "./CustomMenuItemLink";

export const CustomMenu = () => {
  const { permissions } = usePermissions();
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const organizationId = identity?.organization?.id;
  const {
    data: organization,
  } = useGetOne("organizations", { id: organizationId });
  const logoSrc =
    organization?.logo?.public_display_link || "/Icone_App_Home.png";

  if (!permissions) return;

  return (
    <Stack gap={2} sx={{ background: "white", height: "100vh" }}>
      <img
        src={logoSrc}
        alt={"logo"}
        style={{
          width: "140px",
          height: "140px",
          padding: "1em",
          alignSelf: "center",
          borderRadius: "32px",
          objectFit: "contain",
        }}
      />

      {/* Dashboard */}
      <CustomMenuItemLink
        key='dashboard'
        to='/'
        primaryText={translate("resources.dashboard")}
        leftIcon={<DashboardIcon />}
      />

      <CustomMenuItemLink
        key='my-organization'
        to={`/organizations/${organizationId}/show`}
        primaryText={translate("resources.my_organization")}
        leftIcon={<BusinessIcon />}
      />

      {hasAccess({ name: "users", crudType: "list" }, permissions) && (
        <CustomMenuItemLink
          key='my-users'
          to={`/organizations/${organizationId}/users`}
          primaryText={translate("resources.my_users")}
          leftIcon={<GroupIcon />}
        />
      )}

      {/* Experiences */}
      {hasAccess({ name: "experiences", crudType: "list" }, permissions) && (
        <CustomMenuItemLink
          key='my-experiences'
          to='/experiences'
          primaryText={translate("resources.my_experiences")}
          leftIcon={<AppsIcon />}
        />
      )}

      {/* Experience Groups */}
      {hasAccess(
        { name: "experience-groups", crudType: "list" },
        permissions
      ) && (
        <CustomMenuItemLink
          key='experience-groups'
          to='/experience-groups'
          primaryText={translate("resources.experience-groups")}
          leftIcon={<WorkspacesIcon />}
        />
      )}

      {/* Organizations */}
      {hasAccess({ name: "organizations", crudType: "list" }, permissions) && (
        <CustomMenuItemLink
          key='organizations'
          to='/organizations'
          primaryText={translate("resources.organizations")}
          leftIcon={<BusinessIcon />}
        />
      )}

      {/* Tags */}
      {hasAccess({ name: "tags", crudType: "list" }, permissions) && (
        <CustomMenuItemLink
          key='tags'
          to='/tags'
          primaryText={translate("resources.tags")}
          leftIcon={<LabelIcon />}
        />
      )}

      {/* Devices */}
      {hasAccess({ name: "devices", crudType: "list" }, permissions) && (
        <CustomMenuItemLink
          key='devices'
          to='/devices'
          primaryText={translate("resources.devices")}
          leftIcon={<DevicesIcon />}
        />
      )}
    </Stack>
  );
};
