import {FormHelperText, Stack, Typography} from "@mui/material";

interface CustomInputProps {
    inputComponent: React.FC<any>,
    inputComponentProps: any,
    label?: string,
    helperText?: string | JSX.Element,
    fullWidth?: boolean,
    required?: boolean
}

const CustomInput: React.FC<CustomInputProps> = (
    {
        inputComponent: InputComponent,
        inputComponentProps,
        label,
        helperText,
        fullWidth = false,
        required = true
    }) => {
    return (
        <Stack direction={'row'} gap={3} alignItems={'flex-start'}>
            {(label || helperText) && <Stack width={'11em'}>
                {label && <Typography>{label}{required && '*'}</Typography>}
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </Stack>}
            <InputComponent
                {...inputComponentProps}
                label={false}
                sx={{
                    width: '100%',
                    flex: 1,
                    maxWidth: fullWidth ? null : '30em',
                    '& .MuiFormHelperText-root': {
                        display: 'none'
                    },
                    margin: 0
                }}
            />
        </Stack>
    )
}

export default CustomInput;