import { Stack, Typography, Box } from "@mui/material";
import { theme } from "../../global/styles";

interface OptionItemProps {
  title: string;
  children: React.ReactNode;
}

const OptionItem: React.FC<OptionItemProps> = ({ title, children }) => (
  <Stack
    direction='row'
    flexWrap='wrap'
    width='100%'
    alignItems='center'
    gap={4}
  >
    <Typography color={theme.palette.grey[600]} flex={1}>
      {title}
    </Typography>
    <Box flex={1}>{children}</Box>
  </Stack>
);

export default OptionItem;
