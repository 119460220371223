export const incrementVersion = (version: string) => {
  // split string by '.'
  const latestVersionArray = version.split(".");

  if (latestVersionArray.length === 1) {
    latestVersionArray.push("0");
  }

  // get last element
  const latestVersionLastElement =
    latestVersionArray[latestVersionArray.length - 1];

  // if last element = 9, increment second last element and make last element 1
  if (latestVersionLastElement === "9") {
    const latestVersionSecondLastElement =
      latestVersionArray[latestVersionArray.length - 2];

    const latestVersionSecondLastElementIncremented =
      parseInt(latestVersionSecondLastElement) + 1;

    latestVersionArray[latestVersionArray.length - 2] =
      latestVersionSecondLastElementIncremented.toString();

    latestVersionArray[latestVersionArray.length - 1] = "1";
    
    return latestVersionArray.join(".");
  }

  // increment last element
  const latestVersionLastElementIncremented =
    parseInt(latestVersionLastElement) + 1;

  // replace last element
  latestVersionArray[latestVersionArray.length - 1] =
    latestVersionLastElementIncremented.toString();

  // join array
  return latestVersionArray.join(".");
};
