import { useEffect, useState } from "react";
import {
  ArrayField,
  Datagrid,
  EmailField,
  List,
  Loading,
  SingleFieldList,
  TextField,
  TopToolbar,
  usePermissions,
  useRecordContext,
  useRedirect,
  useTranslate,
} from "react-admin";
import dataProvider from "../../data-providers/data-provider";
import { hasAccess } from "../../global/permissions";
import { IRole } from "../../models/role";
import { RoleChipField } from "../Layout/Fields/ChipFields";
import { Link, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CustomCreateButton from "../Layout/Buttons/CustomCreateButton";
import DefaultFilter from "../Layout/Filters/DefaultFilter";
import ListLayout from "../Layout/ListLayout";

const EditUserButton = () => {
  const user = useRecordContext();
  const translate = useTranslate();

  return (
    <Button
      variant='contained'
      component={Link}
      size='small'
      to={`/organizations/${user?.organization_id}/users/${user?.id}`}
      startIcon={<EditIcon />}
    >
      {translate("ra.action.edit")}
    </Button>
  );
};

const UserListActions = () => {
  const { id: organization_id } = useParams();
  const translate = useTranslate();
  return (
    <TopToolbar
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        gap: "2rem",
      }}
    >
      {/* <Button
        variant='text'
        size='small'
        component={Link}
        to={`/organizations/${organization_id}/licenses`}
        color='secondary'
        startIcon={<ChevronRightIcon />}
      >
        {translate("resources.licenses")}
      </Button> */}
      <CustomCreateButton
        to={`/organizations/${organization_id}/users/create`}
        label={translate("users.create")}
      />
    </TopToolbar>
  );
};

const UserList = (props) => {
  const [roles, setRoles] = useState<IRole[]>([]);
  const userOrga = JSON.parse(localStorage.getItem("organization"));
  const redirect = useRedirect();
  const { permissions } = usePermissions();
  const { id: organization_id } = useParams();
  const translate = useTranslate();

  useEffect(() => {
    // roles
    if (roles.length === 0)
      dataProvider
        .getList("roles")
        .then(({ data: roles }) => setRoles(roles))
        .catch((err) => console.error(err));
  }, [roles.length]);

  if (roles.length === 0 || !userOrga || !permissions) return <Loading />;

  if (!hasAccess({ name: "users", crudType: "list" }, permissions))
    redirect("/");

  const filter = { organization_id: organization_id };

  return (
    <ListLayout title={translate("resources.my_users")}>
      <List
        exporter={false}
        perPage={50}
        actions={<UserListActions />}
        filter={filter}
        filters={<DefaultFilter />}
        resource='users'
        emptyWhileLoading
        empty={false}
      >
        <Datagrid bulkActionButtons={false} rowSx={(record, index) => ({
          "& .MuiTableCell-root": {
            padding: '6px 9px',
          },
          "& .MuiTableCell-root .MuiStack-root": {
            gap: '4px',
          },
        })}>
          <TextField
            source='first_name'
            label={translate("profile.first_name")}
          />
          <TextField
            source='last_name'
            label={translate("profile.last_name")}
          />
          <EmailField source='email' label={translate("profile.email")} />

          <ArrayField
            source='roles'
            label={translate("users.roles")}
            sortable={false}
          >
            <SingleFieldList>
              <RoleChipField roles={roles} {...props} />
            </SingleFieldList>
          </ArrayField>
          <EditUserButton />
        </Datagrid>
      </List>
    </ListLayout>
  );
};

export default UserList;
