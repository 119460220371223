import { Box, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
    dot: {
      width: 10,
      height: 10,
      borderRadius: '50%',
      backgroundColor: 'orange',
      animation: '$flash 1.5s infinite alternate ease-in-out',
    },
    '@keyframes flash': {
      from: { opacity: 1 },
      to: { opacity: 0.2 },
    },
  });


const FlashingDot = () => {
    const classes = useStyles();

    return (
      <Tooltip title="Arrive à expiration.">
        <Box className={classes.dot} />
      </Tooltip>
    );
}

export default FlashingDot;