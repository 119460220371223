import React, {useEffect} from "react";
import {useRecordContext, useRefresh, useTranslate} from "react-admin";
import {deleteDeviceActions} from "../../../services/api/devices.service";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import DeviceActionList from "../Actions/DeviceActionList";
import ActionBanner from "../Banners/ActionBanner";

const ActionsDialog = () => {
    const [open, setOpen] = React.useState(false);
    const record = useRecordContext();
    const refresh = useRefresh();
    const translate = useTranslate();

    useEffect(() => {
        if (record?.action_count === 0) setOpen(false);
    }, [record?.action_count]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        try {
            await deleteDeviceActions(record.id as string);
            refresh();
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <React.Fragment>
            <Badge
                badgeContent={record.action_count}
                color="warning"
                overlap="circular"
            >
                <IconButton color="primary" onClick={handleClickOpen} disabled={record.action_count === 0}>
                    <PendingActionsIcon/>
                </IconButton>
            </Badge>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={"md"}
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    {translate("devices.actions.action_dialog.title")}
                </DialogTitle>
                <DialogContent>
                    <ActionBanner
                        doneCount={record.done_count}
                        totalCount={record.action_count}
                        failedCount={record.failed_count}
                        pendingCount={record.pending_count}
                        onDelete={handleDelete}
                    />
                    <DeviceActionList deviceId={record.id as string}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>{translate("misc.close")}</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default ActionsDialog;