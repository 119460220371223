import {Stack, Typography, Box, Paper, Divider, Skeleton} from "@mui/material";
import { XAxis, YAxis, Tooltip, BarChart, Bar } from "recharts";
import { theme } from "../../global/styles";
import { useRef } from "react";
import { useTranslate } from "react-admin";
import CircleIcon from '@mui/icons-material/Circle';

export const DashboardBarChart = ({ data }) => {
  const container = useRef(null);
  const translate = useTranslate();

  const renderTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Stack
          p={1}
          bgcolor='white'
          borderRadius={1}
          border='1px solid lightgray'
        >
          <Typography variant='subtitle1' fontWeight={600}>
            {label}
          </Typography>
          <Divider />
          {payload.map((p: any, index: any) => {
            const color = p.color;
            return (
              <Stack
                key={`typo-${index}`}
                direction='row'
                alignItems='center'
                gap={1}
              >
                <CircleIcon sx={{ color, width: 16, height: 16 }} />
                <Typography variant='subtitle1' fontWeight={500} color='grey'>
                  Total : <span style={{ fontWeight: "bold" }}>{p.value}</span>
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      );
    }
  };

  return (
    <Paper sx={{height: '100%'}} ref={container}>
      {data && Object.keys(data).length > 0 ? (
        <Stack height={'100%'}>
          <Box>
            <Typography borderRadius="18px 18px 0px 0px" color='white' fontWeight={600} bgcolor={theme.palette.primary.main} p={2}>{translate('dashboard.monthly_utilizations')}</Typography>
          </Box>
          <Divider />
          <BarChart
            width={container?.current?.offsetWidth}
            height={370}
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
            title='# sessions'
            style={{flexGrow: 1}}
          >
            <XAxis dataKey='month' />
            <YAxis label={{ value: translate('dashboard.number_of_utilizations'), angle: -90, dx: -30 }} />
            <Tooltip content={renderTooltip} />
            <Bar
              dataKey={'count'}
              fill={theme.palette.primary.main}
              barSize={30}
            />
          </BarChart>
        </Stack>
      ) : (
        <Skeleton variant="rounded" height={440} />
      )}
    </Paper>
  )
}