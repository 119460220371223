import React from "react";
import {Edit} from "react-admin";
import ExperienceGroupsForm from "./Form/ExperienceGroupsForm";

const ExperienceGroupsEdit = () => {
  return (
      <Edit>
          <ExperienceGroupsForm/>
    </Edit>
  );
};

export default ExperienceGroupsEdit;
