import React, { useState } from 'react';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useTranslate } from 'react-admin';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import { theme } from '../../global/styles';

const useStyles = makeStyles({
    menu: {
        "& .MuiList-root.MuiList-padding": {
            padding: 0,
        },
    },
    accordion: {
        boxShadow: "none",
        "&:first-of-type": {
            borderRadius: 0,
        },
        "&.Mui-disabled:first-of-type": {
            marginTop: "6px",
        },
        "&.Mui-expanded": {
            margin: "8px 0",
        },
        "&.Mui-expanded:last-of-type": {
            marginBottom: "8px",
            marginTop: 0,
        },
        "&.Mui-expanded::before": {
            opacity: 1,
        }
    },
    summary: {
        "&.Mui-expanded": {
            minHeight: 0,
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "12px 0",
        }
    },
    details: {
        padding: 0,
        maxHeight: 125,
        overflowY: 'scroll'
    },
});

const NotificationButton = ({ notifications = [] }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const translate = useTranslate();
    const [expanded, setExpanded] = useState(null);

    const licensesNotifications = notifications['licenses'];
    const servicesLicensesNotifications = notifications['service-licenses'];
    const totalNotifications = (licensesNotifications ?? []).length + (servicesLicensesNotifications ?? []).length;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleItemClick = (url:string) => {
        navigate(url);
        handleClose();
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div>
            <IconButton color="inherit" onClick={handleClick}>
                <Badge badgeContent={totalNotifications} color='error'>
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose} className={classes.menu}>
                {totalNotifications === 0 ? (
                    <Typography padding={'1rem'} onClick={handleClose}>{translate("notifications.none")}</Typography>
                ) : (
                    <div>
                        <Typography fontSize={'1.15rem'} variant='h6' padding={'1rem'} paddingBottom={0}>{translate("notifications.title")}</Typography>

                        {Object.keys(notifications).map((key, idx) => {
                            const notificationGroup = notifications[key];
                            const accordionTitle = translate(`resources.${key}`);

                            return(
                                <Accordion className={classes.accordion} disabled={notificationGroup.length === 0} expanded={expanded === `panel${idx}`} onChange={handleChange(`panel${idx}`)}>
                                    <AccordionSummary className={classes.summary} expandIcon={<ExpandMoreIcon />} aria-controls={`panel${idx}d-content`} id={`panel${idx}d-header`}>
                                        <Typography color={theme.palette.customGrey.dark}>{accordionTitle}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.details}>
                                        {notificationGroup.map((notification, index) => {
                                            const experience = notification?.experience?.name ?? notification?.experience_group?.name;
                                            const service = notification?.services?.map(service => service.charAt(0).toUpperCase() + service.slice(1)).join(", ");
                                            let resource = key === 'licenses' ? experience : service;
                                            resource = resource.length > 30 ? resource.slice(0, 30 - 1) + '...' : resource;

                                            const end_time = key === 'licenses' ? notification?.ended_at : notification?.end_date;
                                            const ended_at = moment(end_time).format('DD/MM/YYYY');

                                            const organization = notification?.organization?.name;
                                            const title = [organization, resource, ended_at].filter(Boolean).join(' - ');
                                            const url = `/organizations/${notification?.organization?.id}/${key}/${notification?.id}`;

                                            return (
                                                <MenuItem key={index} onClick={() => {handleItemClick(url)}}>
                                                    <ListItemText primary={title} />
                                                </MenuItem>
                                            );
                                        })}
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                    </div>
                )}
            </Menu>
        </div>
    );
};

export default NotificationButton;
