import React, { useCallback } from "react";
import { useInput } from "react-admin";
import { useDropzone } from "react-dropzone";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

const styles: any = {
  container: {
    position: "relative",
    border: "2px dashed #cccccc",
    textAlign: "center",
    width: "100px",
    height: "100px",
    borderRadius: "16px",
    padding: "2px",
    cursor: "pointer",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: "16px",
  },
  clearButton: {
    zIndex: 1,
    position: "absolute",
    top: -8,
    right: -8,
    backgroundColor: "#f0f0f0",
    width: "1.7rem",
    height: "1.7rem",
    boxShadow:
      "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "#ffebeb",
    },
  },
  placeholderIcon: {
    width: "100%",
    height: "100%",
    padding: 3,
  },
};

const ImageInput = ({ source }) => {
  const { field } = useInput({ source });

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      field.onChange(file);
    },
    [field]
  );

  const clearImage = (event) => {
    event.stopPropagation();
    field.onChange(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpg", ".jpeg", ".png"],
    },
  });

  const getImageSrc = useCallback(() => {
    if (!field.value?.public_display_link) {
      return URL.createObjectURL(field.value);
    }
    return field.value.public_display_link;
  }, [field.value]);

  const renderImage = () => (
    <>
      <img src={getImageSrc()} alt='Icon' style={styles.image} />
      <IconButton
        onClick={clearImage}
        size='small'
        color='error'
        sx={styles.clearButton}
      >
        <ClearIcon fontSize='small' />
      </IconButton>
    </>
  );

  const renderPlaceholder = () => (
    <AddPhotoAlternateIcon sx={styles.placeholderIcon} />
  );

  return (
    <div {...getRootProps()} style={styles.container}>
      <input {...getInputProps()} />
      {field.value ? renderImage() : renderPlaceholder()}
    </div>
  );
};

export default ImageInput;
