import React, { useEffect } from "react";
import { Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DateInput, useTranslate } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import moment from "moment";

const EndDateInput: React.FC = () => {
  const type = useWatch<{ type: string }>({ name: "type" });
  const startDate = useWatch<{ start_date: string }>({ name: "start_date" });
  const endDate = useWatch<{ end_date: string }>({ name: "end_date" });
  const translate = useTranslate();
  const { setValue } = useFormContext();

  useEffect(() => {
    if (!startDate || !endDate) return;
    const start = moment(startDate);
    const end = moment(endDate);
    if (end.isBefore(start)) setValue("end_date", null);
  }, [startDate]);

  const applyPreset = (event, preset) => {
    event.preventDefault();
    const durationMap = {
      "1_month": 1,
      "3_months": 3,
      "6_months": 6,
      "1_year": 12,
      "3_years": 36,
    };
    const duration = durationMap[preset];
    setValue("end_date", moment().add(duration, "month").format("YYYY-MM-DD"));
  };

  if (type !== "paid") return null;

  return (
    <Stack width={"100%"}>
      <ToggleButtonGroup
        color='primary'
        exclusive
        onChange={applyPreset}
        aria-label='Apply preset'
        size={"small"}
        fullWidth
      >
        <ToggleButton value='1_month'>
          {translate("dates.1_month")}
        </ToggleButton>
        <ToggleButton value='3_months'>
          {translate("dates.3_months")}
        </ToggleButton>
        <ToggleButton value='6_months'>
          {translate("dates.6_months")}
        </ToggleButton>
        <ToggleButton value='1_year'>{translate("dates.1_year")}</ToggleButton>
        <ToggleButton value='3_years'>
          {translate("dates.3_years")}
        </ToggleButton>
      </ToggleButtonGroup>
      <DateInput
        source='end_date'
        label={translate("misc.end_date")}
        fullWidth
        name='end_date'
        required
      />
    </Stack>
  );
};

export default EndDateInput;
