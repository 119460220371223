import {FormTab} from "react-admin";
import SkeletonList from "../../../Loading/SkeletonList";
import React, {useEffect, useState} from "react";
import dataProvider from "../../../../data-providers/data-provider";
import TagInput from "../../../Layout/Inputs/TagInput";
import {Stack} from "@mui/material";

export const TagsFormTab = (props) => {
    const [tags, setTags] = useState([]);

    // fetch tags
    useEffect(() => {
        dataProvider
            .getList("tags")
            .then(({data}) => {
                setTags(data);
            })
            .catch((err) => console.log(err));
    }, []);

    return (
        <FormTab label="Tags" {...props}>
            {props.tags.length === 0 && <SkeletonList/>}

            <Stack gap={4}>
                <TagInput source={'editeur_ids'} type={"experienceEditeur"} label={"Editeurs"}
                          createText={"Créer un éditeur"} noOptionsText={"Aucun éditeur trouvé"} required={false}/>

                <TagInput source={'compatible_headset_ids'} type={"experienceHeadset"} label={"Casques compatibles"}
                          createText={"Créer un casque compatible"} noOptionsText={"Aucun casque compatible trouvé"}
                          required={false}/>

                <TagInput
                    source={'label_ids'}
                    type={'experienceTag'}
                    label={'Tags'}
                    createText={'Créer un tag'}
                    noOptionsText={'Aucun tag trouvé'}
                    required={false}
                />
            </Stack>

            {/*{tags.length > 0 && (*/}
            {/*  <SelectArrayInput*/}
            {/*    label="Editeurs"*/}
            {/*    source="editeurs"*/}
            {/*    optionText={<NameField />}*/}
            {/*    optionValue="name"*/}
            {/*    choices={props.tags.filter((t) => t.type === "experienceEditeur")}*/}
            {/*  />*/}
            {/*)}*/}

            {/*{tags.length > 0 && (*/}
            {/*  <SelectArrayInput*/}
            {/*    label="Casques compatibles"*/}
            {/*    source="compatible_headsets"*/}
            {/*    optionText={<NameField />}*/}
            {/*    optionValue="name"*/}
            {/*    choices={props.tags.filter((t) => t.type === "experienceHeadset")}*/}
            {/*  />*/}
            {/*)}*/}

            {/*{tags.length > 0 && (*/}
            {/*  <SelectArrayInput*/}
            {/*    label="Catégories"*/}
            {/*    source="labels"*/}
            {/*    optionText={<NameField />}*/}
            {/*    optionValue="name"*/}
            {/*    choices={props.tags.filter((t) => t.type === "experienceTag")}*/}
            {/*  />*/}
            {/*)}*/}
        </FormTab>
    );
};
