import {FunctionField} from "react-admin";
import {Typography} from "@mui/material";
import React from "react";

const DeviceActionParamsField = ({source, label}) => {
    return (
        <FunctionField
            source={source}
            label={label}
            render={(record) => {
                if (record.action === "install") {
                    return <Typography variant={"body2"}>{record.parsed_params.name}</Typography>;
                }
                if (typeof record.parsed_params === 'object') {
                    return <Typography variant={"body2"}>{record.parsed_params}</Typography>;
                }
                return <Typography variant={"body2"}>{JSON.stringify(record.parsed_params)}</Typography>;
            }}
        />
    );
};

export default DeviceActionParamsField;