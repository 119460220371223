import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useTranslate } from "react-admin";

interface ActionBannerProps {
  doneCount: number;
  failedCount: number;
  pendingCount: number;
  totalCount: number;
  onDelete: () => void;
}

const ActionBanner: React.FC<ActionBannerProps> = ({
  totalCount,
  failedCount,
  doneCount,
  onDelete,
}) => {
  const translate = useTranslate();

  return (
    <Stack
      direction='row'
      spacing={2}
      alignItems='center'
      justifyContent={"space-between"}
      mb={2}
    >
      <Typography variant='body2' color='grey'>
        {translate("devices.actions.action_dialog.banner.done", {
          count: doneCount,
        })}{" "}
        •{" "}
        {translate("devices.actions.action_dialog.banner.errors", {
          count: failedCount,
        })}{" "}
        •{" "}
        {translate("devices.actions.action_dialog.banner.total", {
          count: totalCount,
        })}
      </Typography>
      <Button variant='outlined' color='primary' onClick={onDelete}>
        {translate("devices.actions.action_dialog.banner.delete")}
      </Button>
    </Stack>
  );
};

export default ActionBanner;
