import {Create} from "react-admin";
import TagForm from "./TagForm";
import {Dialog} from "@mui/material";

interface TagCreateProps {
    dialog: boolean;
    type?: string;
}

const TagCreate: React.FC<TagCreateProps> = ({dialog, type}) => {
    const create = (
        <Create>
            <TagForm type={type}/>
        </Create>
    );

    const onCancel = () => {

    }

    if (dialog) {
        return (
            <Dialog open onClose={onCancel}>
                {create}
            </Dialog>
        )
    }

    return create;
};

export default TagCreate;
