import React from "react";
import ExperienceGroupsForm from "./Form/ExperienceGroupsForm";
import {Create} from "react-admin";

const ExperienceGroupsCreate = () => {
  return (
      <Create>
      <ExperienceGroupsForm />
    </Create>
  );
};

export default ExperienceGroupsCreate;
